/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import DatePickerComponent, { CalendarContainer } from "react-datepicker"
import { styles } from "../gatsby-plugin-theme-ui"
import arrowIcon from "../images/carousel-chevron-R.svg"
import CalendarIcon from "../images/calendarBlack.svg"
const Container = ({ className, children }) => {
  return (
    <CalendarContainer className={className}>
      <div
        sx={{
          position: "relative",
          zIndex: 1,
          py: "20px",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          maxWidth: ["100%", "100%", "unset"],
          ".react-datepicker": {
            "&__month-container:last-of-type": {
              ml: ["20px", "20px", "40px"],
              mt: ["40px", "40px", 0],
            },
            "&__day, &__day-name": {
              mx: "3px",
              my: "1px",
              fontSize: "13px",
              width: ["40px", "35px", "30px"],
              height: ["40px", "35px", "30px"],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "&__week": {
              my: ["10px", "5px", 0],
            },
            "&__week, &__day-names": {
              display: "flex",
            },
            "&__month": {},
            "&__day": {
              position: "relative",
              cursor: "pointer",
              borderRadius: "100%",
              ":hover": {
                backgroundColor: "backgrounds.primary",
              },
              "&--disabled": {
                ":hover": {
                  backgroundColor: "transparent",
                },
                cursor: "default",
                color: "#c0c0c0",
              },
              "&--in-range, &--in-selecting-range": {
                backgroundColor: "backgrounds.primary",
                "::after": {
                  content: "''",
                  width: ["46px", "41px", "28px"],
                  height: ["40px", "35px", "30px"],
                  top: 0,
                  backgroundColor: "backgrounds.primary",
                  position: "absolute",
                  right: "18px",
                  zIndex: -1,
                },
                ":last-of-type": {
                  "::after": {
                    width: ["38px", "23px", "28px"],
                    right: ["24px", "23px", "18px"],
                  },
                },
                ":first-of-type": {
                  "::after": {
                    width: ["28px", "23px", "28px"],
                    left: ["28px", "23px", "18px"],
                  },
                },
              },
              "&--outside-month": {
                opacity: 0,
                pointerEvents: "none",
              },
              "&--selected, &--range-start, &--range-end, &--selecting-range-start": {
                borderRadius: "100%",
                lineHeight: "13px",
                color: "white",
                ":hover": {
                  backgroundColor: "secondary",
                },
                backgroundColor: "primary",
              },
              "&--range-start, &--selecting-range-start": {
                "::after": {
                  width: ["28px", "23px", "18px"],
                  left: ["28px", "23px", "18px"],
                },
              },
              "&--range-end": {
                "::after": {
                  width: ["28px", "23px", "38px"],
                  right: ["28px", "23px", "15px"],
                },
              },
            },
            "&__day-name": {
              display: "block",
              textAlign: "center",
              fontWeight: 700,
              fontSize: 0,

              "&::first-letter": {
                fontSize: "13px",
                ml: "7px",
              },
            },
            "&__current-month": {
              ml: "12px",
              fontSize: "15px",
              fontWeight: 600,
            },
            "&__navigation--next": {
              order: 3,
            },
          },
        }}
      >
        {children}
      </div>
    </CalendarContainer>
  )
}

const DateRangePicker = ({ selectedStartDate, selectedEndDate, onChange }) => {
  const [date, setDate] = React.useState(null)
  const startDate = new Date()
  const dateToString = date => {
    var dd = String(date.getDate()).padStart(2, "0")
    var mm = String(date.getMonth() + 1).padStart(2, "0")
    var yyyy = date.getFullYear()

    return mm + "/" + dd + "/" + yyyy
  }
  return (
    <div sx={{ ".react-datepicker-popper": { zIndex: 1 } }}>
      <DatePickerComponent
        minDate={startDate}
        forceShowMonthNavigation={true}
        nextMonthButtonLabel={
          <img
            alt=""
            sx={{
              height: "33px",
              ml: ["5px", "5px", "5px", "10px"],
              display: "block",
            }}
            src={arrowIcon}
          />
        }
        previousMonthButtonLabel={
          <img
            alt=""
            sx={{
              height: "33px",
              transform: "rotate(180deg)",
              mr: ["5px", "5px", "5px", "10px"],
              display: "block",
            }}
            src={arrowIcon}
          />
        }
        onChange={dates => {
          onChange(dates)
        }}
        calendarContainer={Container}
        useWeekdaysShort={true}
        focusSelectedMonth={true}
        selected={selectedStartDate}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        monthsShown={2}
        allowSameDay
        selectsRange
        peekNextMonth={false}
        inline
      />
    </div>
  )
}
export default DateRangePicker
