/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import DatePicker from "../date-picker"
const FieldDate = ({ register, field, validate, setValue, watchData }) => {
  const dateToString = date => {
    var dd = String(date.getDate()).padStart(2, "0")
    var mm = String(date.getMonth() + 1).padStart(2, "0")
    var yyyy = date.getFullYear()

    return mm + "/" + dd + "/" + yyyy
  }
  register(
    { name: field.name },
    { required: !!field.required, ...(validate && { validate }) }
  )
  const [date, setDate] = React.useState(null)
  const setFieldValue = React.useCallback(value => setDate(new Date(value)), [
    setValue,
  ])
  React.useEffect(() => {
    if (date) setValue(field.name, dateToString(date), { shouldDirty: true })
  }, [setValue, field, date])
  React.useEffect(() => {
    if (field.onDataChange) field.onDataChange(setFieldValue, watchData)
  }, [watchData])
  return (
    <DatePicker
      minDate={field.getMinDate && field.getMinDate(watchData)}
      date={date}
      onChange={date => setDate(date)}
    />
  )
}
export default FieldDate
