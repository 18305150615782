/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Input from "../input"
const FieldInput = ({
  isDisabled,
  field,
  register,
  validate,
  fieldError,
  watchData,
  setValue,
}) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const setFieldValue = value => setValue(field.name, value)
  const onDataChange = () => {
    if (field.onDataChange && !isFocused)
      field.onDataChange(setFieldValue, watchData)
  }
  React.useEffect(() => {
    onDataChange()
  }, [watchData, setValue, field])
  return (
    <Input
      maxLength={255}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false)
        onDataChange()
      }}
      disabled={isDisabled}
      name={field.name}
      ref={register({
        required: !!field.required,
        ...(validate && { validate }),
      })}
      sx={{
        ...(fieldError && { borderColor: "primary" }),
        width: "100%",
      }}
      {...field.attributes}
    />
  )
}
export default FieldInput
