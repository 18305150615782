/** @jsx jsx */
import { jsx } from "theme-ui"

const Label = ({ name, ...restProps }) => {
  return (
    <label
      sx={{ fontSize: "12px", color: "#a5a5a5", mb: "5px", display: "block" }}
      htmlFor={name}
      {...restProps}
    />
  )
}
export default Label
