/** @jsx jsx */
import { jsx } from "theme-ui"
import Textarea from "../textarea"
const FieldTextarea = ({ field, register, validate, fieldError }) => {
  return (
    <Textarea
      name={field.name}
      ref={register({
        required: !!field.required,
        ...(validate && { validate }),
        ...(field.maxLength && { maxLength: field.maxLength }),
      })}
      sx={{
        ...(fieldError && { borderColor: "primary" }),
        resize: "none",
        width: "100%",
        minHeight: field.height || "150px",
      }}
      {...(field.maxLength && { maxLength: field.maxLength })}
    />
  )
}
export default FieldTextarea
