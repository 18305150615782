/** @jsx jsx */
import { jsx } from "theme-ui"
import { swatches } from "../../gatsby-plugin-theme-ui"
import Link from "../link"

const OrganizationAccent = ({ organization, submitted }) => {
  return (
    <div
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundColor: [
          swatches.secondary.background,
          "swatches.primary.tan",
          "swatches.primary.tan",
        ],
        display: "flex",
        justifyItems: "center",
      }}
    >
      <div
        sx={{
          position: "absolute",
          zIndex: 2,
          width: "100%",
          height: "100%",
          clipPath: "polygon(100% 0%, 12% 0%, 4% 100%, 93% 100%, 100% 16%)",
          backgroundColor: swatches.secondary.background,
          display: ["none", "block", "block"],
        }}
      />
      <div sx={{ zIndex: 3, width: "100%" }}>
        <div
          sx={{
            py: "50px",
            display: "flex",
            height: "100%",
            maxWidth: submitted
              ? ["100%", "590px", "660px"]
              : ["100%", "590px", "590px", "100%"],
            margin: "0 auto",
            flexDirection: ["column", "row", "row"],
            alignItems: "center",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexGrow: submitted ? 0 : [0, 0, 0, 1],
              width: "100%",
              justifyContent: submitted
                ? ["center", "none", "none", "none"]
                : ["center", "none", "none", "flex-end"],
              mr: "20px",
              mb: ["20px", 0],
            }}
          >
            {organization.logo && (
              <img
                alt={organization.name}
                src={organization.logo.fields.file.url}
                sx={{
                  maxWidth: ["200px", "200px", "200px"],
                  maxHeight: ["180px", "180px", "180px"],
                }}
              />
            )}
          </div>
          <div
            sx={{
              minWidth: submitted
                ? ["100%", "70%"]
                : ["70%", "70%", "70%", "680px"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              px: ["40px", 0],
            }}
          >
            <h5 sx={{ m: 0 }}>{organization.name}</h5>
            {organization.address && (
              <h6 sx={{ fontSize: "14px", mb: "18px" }}>
                {organization.address}
              </h6>
            )}
            {organization.phone && (
              <p sx={{ fontSize: "14px", mb: 0 }}>{organization.phone}</p>
            )}
            {organization.website && (
              <Link
                forceExternal={true}
                link={organization.website}
                target="_blank"
              >
                <p
                  sx={{
                    fontSize: "14px",
                    mb: "18px",
                    color: swatches.primary.red,
                  }}
                >
                  {organization.website}
                </p>
              </Link>
            )}
            {organization.description && (
              <p
                sx={{
                  width: "100%",
                  fontSize: "14px",
                  mb: 0,
                  lineHeight: "16px",
                }}
              >
                {organization.description}
              </p>
            )}
          </div>
          <div
            sx={{
              display: submitted ? "none" : ["none", "none", "none", "block"],
              flexGrow: submitted ? 0 : 1,
              width: "100%",
            }}
          >
            <h3></h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganizationAccent
