import React from "react"
import { useContentfulClient } from "../hooks/use-contentful-client"

export const useCollectionService = () => {
  const { getClient } = useContentfulClient()
  const [response, setResponse] = React.useState({
    data: null,
    loading: false,
    error: null,
  })
  const checkNameIsUnique = React.useCallback(
    async name => {
      const entries = await getClient().getEntries({
        include: 2,
        content_type: "collection",
        "fields.name": name,
      })
      return !entries.total
    },
    [getClient]
  )
  const findCollections = React.useCallback(
    async query => {
      setResponse(response => ({ ...response, loading: true }))
      try {
        const entries = await getClient().getEntries({
          include: 2,
          content_type: "collection",
          ...query,
        })
        setResponse({
          data: entries.items.map(item => item),
          loading: false,
        })
      } catch (err) {
        setResponse(response => ({ ...response, loading: false, error: err }))
      }
    },
    [setResponse, getClient]
  )
  const findCollectionsByPartialName = React.useCallback(
    async (partial, organization) => {
      setResponse(response => ({ ...response, loading: true }))
      try {
        const entries = await getClient().getEntries({
          content_type: "collection",
          ...(partial && { "fields.name[match]": partial }),
          "fields.organization.sys.contentType.sys.id": "organization",
          "fields.organization.fields.name": organization.name,
          limit: 50,
        })
        setResponse({
          data: entries.items.map(item => item.fields),
          loading: false,
        })
      } catch (err) {
        setResponse(response => ({ ...response, loading: false, error: err }))
      }
    },
    [setResponse, getClient]
  )

  return {
    checkNameIsUnique,
    findCollectionsByPartialName,
    findCollections,
    response,
  }
}
