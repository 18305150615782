/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Form from "./form"
import { useOrganizationService } from "../services/organization-service"
import Link from "./link"
const OrganizationForm = ({ ...restProps }) => {
  const validateSize = ({ maxW }) => async data =>
    await new Promise(resolve => {
      const urlCreator = window.URL || window.webkitURL
      const imageUrl = urlCreator.createObjectURL(data[0])
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        resolve(maxW ? image.width <= maxW : true)
      }
    })

  const {
    post,
    response: postedEntry,
    checkNameIsUnique,
  } = useOrganizationService()
  const sections = [
    {
      marginBottom: true,
      fields: [
        {
          name: "name",
          label: "Organization Name*",
          required: true,
          type: "input",
          fullWidth: true,
          validations: [
            {
              name: "unique",
              fn: () => checkNameIsUnique,
              error: "This Organization Name has already been registered",
            },
          ],
        },
      ],
    },
    {
      marginBottom: false,
      fields: [
        { name: "website", label: "Website*", required: true, type: "input" },
        { name: "phone", label: "Phone Number", type: "input" },
      ],
    },
    {
      marginBottom: true,
      fields: [
        {
          name: "address",
          label: "Address",
          type: "input",
        },
        {
          type: "group",
          sx: {
            mb: 0,
            "&>div": {
              p: 0,
              border: 0,
              display: "flex",
              flexDirection: "row",
              div: {
                width: "50%",
              },
            },
          },
          fields: [
            {
              name: "city",
              label: "City",
              type: "input",
              sx: { pr: "10px" },
            },
            {
              name: "zip",
              label: "Zip",
              type: "input",
              sx: { pl: "10px" },
            },
          ],
        },
        {
          name: "description",
          maxLength: 400,
          label: "Organization Description*",
          required: true,
          type: "textarea",
        },
        {
          name: "logo",
          label: "Upload Organization Logo",
          type: "image",
          validations: [
            {
              name: "maxWidth",
              fn: () => validateSize({ maxW: 400 }),
              error: (
                <div sx={{ lineHeight: "16px" }}>
                  <span>
                    Your image is too large and does not match the required
                    format listed.
                  </span>
                </div>
              ),
            },
          ],
          children: (
            <React.Fragment>
              <p sx={{ mb: "15px" }}>
                Please upload your logo at{" "}
                <span sx={{ fontWeight: 600 }}>200 x 200 pixels, </span>
                JPG or PNG saved for web.
              </p>
              <p sx={{ mb: "30px" }}>
                Drag and drop here or choose a file on your local drive.
              </p>
            </React.Fragment>
          ),
        },
        {
          label: "Social Properties",
          type: "group",
          fields: [
            { name: "facebook", label: "Facebook", type: "input" },
            { name: "instagram", label: "Instagram", type: "input" },
            { name: "twitter", label: "Twitter", type: "input" },
            { name: "youtube", label: "YouTube", type: "input" },
          ],
        },
      ],
    },
    {
      marginBottom: true,
      fields: [
        {
          name: "email",
          label: "Email*",
          required: true,
          type: "email",
          validations: [
            {
              name: "validEmail",
              fn: () => data =>
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  data
                ),
              error: "Invalid Email",
            },
          ],
        },
        {
          name: "emailVerify",
          label: "Verify Email*",
          required: true,
          type: "email",
          validations: [
            {
              name: "verifies",
              fn: watchData => data => watchData["email"] === data,
              error: "The fields don't match",
            },
          ],
        },
      ],
    },
  ]
  const onSubmit = data => {
    const { logo, ...fields } = data
    post({
      data: fields,
      files: { logo },
      callback: () => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
      },
    })
  }
  const errorMessages = {
    429: "Please wait 60 seconds before sending another request.",
    422: "Submitted data has validation errors.",
    500: "Internal server error, please try again.",
  }
  return (
    <div {...restProps}>
      {!postedEntry.data ? (
        <React.Fragment>
          <Form
            sections={sections}
            submitting={postedEntry.loading}
            onSubmit={onSubmit}
          />
          <div sx={{ color: "primary", textAlign: "center" }}>
            {postedEntry.error && errorMessages[postedEntry.error]}
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div sx={{ px: "30px" }}>
            <h3>Your organization is submitted for approval</h3>
            We will review and approve your submission within 24 hours.
          </div>
          <div
            sx={{
              backgroundColor: "backgrounds.secondary",
              my: "20px",
              py: "20px",
              px: "30px",
            }}
          >
            In the meantime, you can upload your event(s) for approval. Use the
            link below to get started:
            <div>
              <Link
                sx={{
                  textDecoration: "underline",
                  fontSize: "18px",
                  mt: "10px",
                  display: "block",
                  color: "#2388fa",
                  ":hover": {
                    color: "#2388fa",
                  },
                }}
                link={`/event-submission/${postedEntry.data.data.fields.secretSlug["en-US"]}`}
              >
                www.sfarts.org/event-submission/
                {postedEntry.data.data.fields.secretSlug["en-US"]}
              </Link>
            </div>
          </div>
          <div sx={{ color: "#888", px: "30px", "& p": { mb: "10px" } }}>
            <p>
              This is your organization's submission link. Please keep it in a
              safe place and always use this link to submit your events.
            </p>
            <p>
              The link has also been emailed to you. Any time you want to submit
              events, use the link to access the form to do so.
            </p>
            <p sx={{ fontStyle: "italic" }}>
              Note: Keep this link secure and only share with people within your
              organization that you want to be able to upload events.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrganizationForm
