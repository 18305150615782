/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useEventService } from "../services/event-service"
import DetailHero from "./detail-hero"
import Container from "./container"
import CollectionCard from "./collection-card"
import SpinnerSection from "./spinner-section"
import { formatRequestDate, renderLineBreak } from "../helpers"
import OrganizationInfoSection from "./event-form/organization-info-section"
import EventCardMini from "./event-card-mini"

const EventDetail = ({ event, ...restProps }) => {
  const {
    findEvents: findEventsInNeighborhood,
    response: { data: eventsInNeighborhood },
  } = useEventService()
  React.useEffect(() => {
    if (event && findEventsInNeighborhood) {
      findEventsInNeighborhood({
        "sys.id[ne]": event.contentful_id,
        "fields.venue.sys.contentType.sys.id": "venue",
        "fields.eventEndDate[gte]": formatRequestDate(new Date()),
        "fields.venue.fields.neighborhood": event.venue.neighborhood,
        order: "fields.eventEndDate",
        limit: 3,
        include: 2,
      })
    }
  }, [findEventsInNeighborhood, event])
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "white",
      }}
    >
      <DetailHero
        type={"event"}
        entry={{
          image: event.eventImage?.file?.url && event.eventImage.file.url,
          discipline: event.discipline?.disciplineName,
          name: event.eventName,
          description: event.eventDescription?.eventDescription,
          startDate: event.eventStartDate,
          endDate: event.eventEndDate,
          from: event.from,
          to: event.to,
          exceptions: event.timeOrDateExceptions,
          venue: event.venue,
          ticketUrl: event.ticketUrl,
          eventOrVenueUrl:
            event.eventOrVenueUrl && event.eventOrVenueUrl.eventOrVenueUrl,
        }}
      />
      <Container sx={{ pt: "20px", pb: 0 }}>
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            justifyContent: "space-between",
            pb: "30px",
            borderBottom: "1px solid #A5A5A5",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              width: ["100%", "100%", "48%"],
              m: 0,
            }}
          >
            <h5 sx={{ mb: "11px" }}>About</h5>
            <p>{renderLineBreak(event.eventDescription?.eventDescription)}</p>
          </div>
          {event.curatorInsight && (
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                justifySelf: "flex-end",
                width: ["100%", "100%", "383px"],
              }}
            >
              <h6 sx={{ mb: "11px" }}>SF/Arts Curator Insight</h6>
              <p
                sx={{
                  fontSize: "16px",
                  fontFamily: "Galaxie Copernicus Semibold Italic",
                }}
              >
                {renderLineBreak(event.curatorInsight.curatorInsight)}
              </p>
              {event.curator && (
                <div sx={{ display: "flex", flexDirection: "row" }}>
                  <div
                    sx={{
                      height: "112px",
                      width: "112px",
                      backgroundImage: `url(${event.curator.image.file.url}?w=256&h=256)`,
                      backgroundSize: "cover",
                      mr: "20px",
                    }}
                  />
                  <div>
                    <h6
                      sx={{
                        mb: "5px",
                        fontSize: "20px",
                        transform: "uppercase",
                      }}
                    >
                      {event.curator.name}
                    </h6>
                    <p sx={{ fontSize: "15px" }}>
                      {event.curator.position || "SF/Arts Curator"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
      {event.collection && (
        <Container sx={{ mt: "20px" }}>
          <div sx={{ borderBottom: "1px solid #c0c0c0", pb: "50px" }}>
            <h6>Part of This Collection</h6>
            <CollectionCard
              sx={{ width: "280px" }}
              hideDetails={true}
              collection={{
                id: event.collection.contentful_id,
                image: event.collection.image.file.url,
                disciplineName: event.collection.discipline?.disciplineName,
                name: event.collection.name,
                description: event.collection.description.description,
                startDate: event.collection.startDate,
                endDate: event.collection.endDate,
                organizationName: event.collection.organization.name,
              }}
            />
          </div>
        </Container>
      )}
      {event.organization && (
        <OrganizationInfoSection organization={event.organization} />
      )}
      {eventsInNeighborhood ? (
        eventsInNeighborhood.length > 0 && (
          <Container
            outerStyle={{
              backgroundColor: "backgrounds.secondary",
              py: [0, 0, "40px"],
            }}
          >
            <h6>Other Events in this Area</h6>
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "column", "row"],
                flexWrap: "wrap",
              }}
            >
              {eventsInNeighborhood.map((event, index) => (
                <EventCardMini
                  key={index}
                  sx={{
                    pr: [0, 0, "19px"],
                    pb: "10px",
                    ":last-of-type": { pr: 0, pb: 0 },
                    width: ["100%", "100%", "50%", `${100 / 3}%`],
                  }}
                  event={{
                    ...event.fields,
                    contentful_id: event.sys.id,
                    organization: {
                      name: event.fields.organization.fields.name,
                    },
                    discipline: {
                      disciplineName:
                        event.fields.discipline?.fields.disciplineName,
                    },
                    eventImage: {
                      file: { url: event.fields.eventImage.fields.file.url },
                    },
                  }}
                />
              ))}
            </div>
          </Container>
        )
      ) : (
        <SpinnerSection />
      )}
    </div>
  )
}

export default EventDetail
