import React from "react"

const useEventCalendarCollections = (
  timeFilteredTodayEvents,
  weekEvents,
  monthEvents,
  groupedFutureEvents
) => {
  return React.useMemo(() => {
    if (
      !(
        timeFilteredTodayEvents &&
        weekEvents &&
        monthEvents &&
        groupedFutureEvents
      )
    )
      return {}
    const getUniqueCollections = events => {
      const collectionMap = {}
      events
        .filter(event => event.fields.collection)
        .map(event => event.fields.collection)
        .forEach(collection => {
          collectionMap[collection.sys.id] = collection
        })
      return Object.values(collectionMap)
    }
    const todayCollections = getUniqueCollections(timeFilteredTodayEvents)
    let used = [...todayCollections.map(col => col.sys.id)]
    const weekCollections = getUniqueCollections(weekEvents).filter(
      collection => !used.includes(collection.sys.id)
    )
    used = [...used, ...weekCollections.map(col => col.sys.id)]
    const monthCollections = getUniqueCollections(monthEvents).filter(
      collection => !used.includes(collection.sys.id)
    )
    used = [...used, ...monthCollections.map(col => col.sys.id)]
    const futureCollections = {}
    groupedFutureEvents.forEach(month => {
      const collections = getUniqueCollections(month.events).filter(
        collection => !used.includes(collection.sys.id)
      )
      used = [...used, ...collections.map(col => col.sys.id)]
      futureCollections[month.title] = collections
    })
    return {
      todayCollections,
      weekCollections,
      monthCollections,
      futureCollections,
    }
  }, [timeFilteredTodayEvents, weekEvents, monthEvents, groupedFutureEvents])
}

export { useEventCalendarCollections }
