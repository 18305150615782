/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import StoryCardMini from "../story-card-mini"

const getRandomColors = arr => {
  const colors = [
    "swatches.primary.green",
    "swatches.primary.teal",
    "swatches.primary.tan",
  ]
  const randomColors = []
  arr.forEach((item, index) => {
    const filteredColors = colors.filter(
      color =>
        color !== randomColors[index - 1] && color !== randomColors[index - 3]
    )
    randomColors[index] =
      filteredColors[Math.floor(Math.random() * filteredColors.length)]
  })
  return randomColors
}

const RecentlyViewedStories = ({ stories, ...restProps }) => {
  const randomColors = React.useMemo(() => getRandomColors(stories), [stories])

  return (
    <div sx={{ mb: "40px" }} {...restProps}>
      <h3
        sx={{
          fontSize: "30px",
          mt: "40px",
        }}
      >
        Recently Viewed Stories
      </h3>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"],
          gap: "20px",
        }}
      >
        {stories.map((story, index) => (
          <StoryCardMini
            key={index}
            story={story}
            sx={{
              ...(randomColors && { backgroundColor: randomColors[index] }),
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default RecentlyViewedStories
