/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import StoryCardMini from "./story-card-mini"
import StoryCard from "./story-card"

const RecentStories = ({ stories }) => {
  return (
    <React.Fragment>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"],
          columnGap: "2px",
          rowGap: "3px",
          mb: "18px",
        }}
      >
        {stories[0] && (
          <StoryCard
            sx={{
              backgroundColor: "swatches.primary.teal",
              display: "flex",
            }}
            story={stories[0]}
          />
        )}
        {stories[1] && (
          <StoryCard
            sx={{
              backgroundColor: "swatches.primary.tan",
              display: "flex",
            }}
            story={stories[1]}
          />
        )}
        {stories[2] && (
          <StoryCard
            sx={{
              backgroundColor: "swatches.primary.green",
              display: "flex",
            }}
            story={stories[2]}
          />
        )}
      </div>

      <div
        sx={{
          display: ["grid"],
          gridTemplateColumns: [
            "1fr",
            "1fr",
            "1fr 1fr",
            "1fr 1fr",
            "1fr 1fr 1fr",
          ],
          columnGap: "2px",
          rowGap: "3px",
        }}
      >
        {stories.slice(3).map((story, index) => (
          <StoryCardMini
            key={index}
            type="secondary"
            sx={{
              backgroundColor: "swatches.primary.tan",
              display: "flex",
              mb: "18px",
            }}
            story={story}
          />
        ))}
      </div>
    </React.Fragment>
  )
}

export default RecentStories
