/** @jsx jsx */
import { jsx } from "theme-ui"
import CTA from "../cta"

const CTACard = ({ content, ...restProps }) => {
  return (
    <CTA
      sx={{ mt: 3 }}
      link={content.url}
      classes={content.classes}
      {...restProps}
    >
      {content.title}
    </CTA>
  )
}
export default CTACard
