/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useVenueService } from "../../../services/venue-service"
import FilterOptionButton from "./filter-option-button"

const MoreOptionsFilter = ({
  moreOptions,
  selectedMoreOptions,
  setSelectedMoreOptions,
  currentResults,
  ...restProps
}) => {
  const toggleOption = name => {
    setSelectedMoreOptions(selected =>
      selected.includes(name)
        ? selected.filter(e => e !== name)
        : [...selected, name]
    )
  }
  return (
    <div {...restProps}>
      <h5
        sx={{
          fontSize: "18px",
          borderBottom: "1px solid #c0c0c0",
          pb: "5px",
          mb: "20px",
        }}
      >
        More Options
      </h5>
      <div>
        {moreOptions &&
          Object.keys(moreOptions).map((option, index) => (
            <FilterOptionButton
              key={index}
              disabled={
                currentResults && !currentResults.some(moreOptions[option])
              }
              active={selectedMoreOptions.includes(option)}
              onClick={() => toggleOption(option)}
              sx={{
                mr: "5px",
                color: selectedMoreOptions.includes(option)
                  ? "primary"
                  : "body",
              }}
            >
              {option}
            </FilterOptionButton>
          ))}
      </div>
    </div>
  )
}

export default MoreOptionsFilter
