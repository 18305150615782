/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import EventCard from "../components/event-card"
import CollectionCard from "./collection-card"
import SwipeableView from "./swipeable-view"
import { useMedia } from "react-use"
import { mediaQueryList } from "../helpers"

const EventCarousel = ({ events, collections, title, sort }) => {
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[2])
  const all = React.useMemo(
    () =>
      collections &&
      events &&
      [
        ...events.map(event => ({
          type: "event",
          endDate: event.fields.eventEndDate,
          startDate: event.fields.eventStartDate,
          entry: event,
        })),
        ...collections.map(collection => ({
          type: "collection",
          endDate: collection.fields.endDate,
          startDate: collection.fields.startDate,
          entry: collection,
        })),
      ].sort(sort || ((a, b) => new Date(a.endDate) - new Date(b.endDate))),
    [collections, events]
  )
  const onNext = activeIndex => {
    if (!mediaQueryMatch) {
      return activeIndex + 1
    } else return activeIndex + 4
  }
  const onPrev = activeIndex => {
    if (!mediaQueryMatch) {
      return activeIndex === 0 ? 0 : activeIndex - 1
    } else {
      if (activeIndex > 4) return activeIndex - 4
      else return 0
    }
  }
  const renderEntry = (type, entry) => {
    if (type === "event")
      return (
        <EventCard
          key={entry.sys.id}
          sx={{ flexGrow: 1, margin: "1px" }}
          event={{
            ...entry.fields,
            contentful_id: entry.sys.id,
            eventImage:
              entry.fields.eventImage && entry.fields.eventImage.fields,
            organization:
              entry.fields.organization && entry.fields.organization.fields,
            experience:
              entry.fields.experience && entry.fields.experience.fields,
            discipline:
              entry.fields.discipline && entry.fields.discipline.fields,
            venue: entry.fields.venue && entry.fields.venue.fields,
            collection:
              entry.fields.collection && entry.fields.collection.fields,
          }}
        />
      )
    return (
      <CollectionCard
        key={entry.sys.id}
        sx={{ flexGrow: 1, margin: "1px" }}
        collection={{
          name: entry.fields.name,
          id: entry.sys.id,
          image: entry.fields.image.fields.file.url,
          disciplineName: entry.fields.discipline?.fields.disciplineName,
          description: entry.fields.description,
          startDate: entry.fields.startDate,
          endDate: entry.fields.endDate,
          organizationName: entry.fields.organization.fields.name,
        }}
      />
    )
  }
  return (
    <div>
      {all ? (
        <div>
          <div>
            <h3
              sx={{
                fontSize: "30px",
              }}
            >
              {title}
            </h3>
          </div>
          <SwipeableView
            visibleElements={[1, 2, 2, 4, 4, 4, 4]}
            bleeding={[100, 50, 80, 0, 0, 0, 0]}
            onNext={onNext}
            onPrev={onPrev}
          >
            {all.map(({ type, entry }) => renderEntry(type, entry))}
          </SwipeableView>
        </div>
      ) : null}
    </div>
  )
}

export default EventCarousel
