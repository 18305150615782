/** @jsx jsx */
import { jsx } from "theme-ui"
import Radio from "../radio"
const FieldRadioGroup = ({
  register,
  field,
  setValue,
  watchData,
  validate,
}) => {
  register(
    { name: field.name },
    { required: !!field.required, ...(validate && validate) }
  )
  return (
    <div>
      {field.options &&
        field.options.map(option => (
          <Radio
            key={option}
            readOnly={true}
            name={`${field.name}.${option}`}
            onClick={() => setValue(field.name, option, { shouldDirty: true })}
            checked={watchData[field.name] === option}
          >
            {option}
          </Radio>
        ))}
    </div>
  )
}
export default FieldRadioGroup
