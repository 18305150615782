/** @jsx jsx */
import { jsx } from "theme-ui"
import ContentCard from "../content-card"
import Container from "../container"
import RichText from "../rich-text"
import Link from "../../components/link"

const GrantsAboutPattern = ({ blade }) => {
  return (
    <Container
      outerStyle={{
        backgroundColor: "backgrounds.primary",
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          maxWidth: "643px",
          borderBottom: "2px solid #D5D3D3",
        }}
      >
        <h5 sx={{ display: ["block", "block", "none"], textAlign: "center" }}>
          {blade.title}
        </h5>
        <RichText
          data={blade.body}
          textStyle={{
            textAlign: "center",
            maxWidth: "643px",
            fontSize: "17px",
            mx: "auto",
          }}
        />
        <div
          sx={{
            mx: "auto",
            display: "flex",
            mt: "20px",
            mb: "39px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {blade.contentCards &&
            blade.contentCards.map((card, index) => (
              <Link link={card?.url} key={index} target="_blank">
                <ContentCard
                  sx={{ mx: ["30px", "30px", "50px"] }}
                  hideImage={false}
                  card={card}
                />
              </Link>
            ))}
        </div>
      </div>
    </Container>
  )
}

export default GrantsAboutPattern
