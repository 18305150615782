/** @jsx jsx */
import { jsx } from "theme-ui"
import Hero from "../hero"
import ContentCard from "../content-card"

const HeroPattern = ({ blade }) => {
  return (
    <Hero desktopImage={blade.desktopImage}>
      <ContentCard
        renderRootImage={false}
        card={{ ...blade, patternType: "Blade" }}
      />
      {blade.contentCards &&
        blade.contentCards.map(card => <ContentCard card={card} />)}
    </Hero>
  )
}

export default HeroPattern
