/** @jsx jsx */
import { jsx } from "theme-ui"

const FilterOptionButton = ({ children, active, ...restProps }) => (
  <button
    sx={{
      color: active ? "primary" : "body",
      backgroundColor: active ? "backgrounds.primary" : "transparent",
      p: "10px",
      width: "100%",
      fontSize: "18px",
      mb: ["15px", "15px", "5px"],
      textAlign: "left",
      ":hover": {
        backgroundColor: "backgrounds.primary",
      },
    }}
    {...restProps}
  >
    {children}
  </button>
)
export default FilterOptionButton
