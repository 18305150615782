/** @jsx jsx */
import { jsx } from "theme-ui"
import CTA from "../../cta"

const FilterBarButton = ({
  children,
  onClick,
  active,
  selected,
  onClear,
  ...restProps
}) => (
  <CTA
    {...(onClick && { onClick })}
    sx={{
      lineHeight: "14px",
      position: "relative",
      flexGrow: [1, 1, "unset"],
      minWidth: "46px",
      maxWidth: ["114px", "114px", "130px", "200px"],
      height: "46px",
      mr: [0, 0, "14px"],
      fontSize: "14px",
      display: "flex",
      flexDirection: "row",
      ...(onClick && { cursor: "pointer" }),
      border: "1px solid white",
      transition: 0,
      ":hover": {
        backgroundColor: "white",
        borderColor: "primary",
        color: "primary",
      },
      ...((active || selected) && {
        borderColor: "primary",
        fontSize: "14px",
        fontWeight: 500,
      }),
    }}
    {...restProps}
  >
    <div
      sx={{
        width: ["100%", "100%", "70%"],
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </div>
    {active && (
      <div
        onClick={e => {
          e.stopPropagation()
          onClear()
        }}
        sx={{
          display: ["none", "none", "block"],
          position: "absolute",
          right: "10px",
          top: "12px",
          lineHeight: "10px",
          p: "5px",
          ":hover": { backgroundColor: "backgrounds.secondary" },
        }}
      >
        &#10005;
      </div>
    )}
  </CTA>
)
export default FilterBarButton
