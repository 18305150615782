/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { styles } from "../gatsby-plugin-theme-ui/index"

const Input = React.forwardRef((props, ref) => (
  <input
    sx={{
      ...styles.input,
    }}
    ref={ref}
    {...props}
  ></input>
))
export default Input
