/** @jsx jsx */
import { jsx } from "theme-ui"
import { Spinner } from "@theme-ui/components"
import { useForm } from "react-hook-form"
import CTA from "../cta"
import Field from "./field"
const Form = ({
  sections,
  submitting,
  onSubmit,
  type,
  submitText,
  ...restProps
}) => {
  const { handleSubmit, register, errors, watch, setValue } = useForm({
    mode: "onBlur",
  })
  const watchData = watch()
  return (
    <form onSubmit={handleSubmit(onSubmit)} {...restProps}>
      <div
        sx={{
          pointerEvents: submitting ? "none" : "all",
          opacity: submitting ? 0.4 : 1,
        }}
      >
        {sections.map((section, index) => (
          <div
            key={index}
            sx={{
              ...(section.type !== "halfWidth"
                ? {
                    columnCount: section.forceTwoColumn ? 2 : [1, 2, 2],
                    mb: section.marginBottom ? "20px" : 0,
                    columnGap: "30px",
                  }
                : {
                    display: "grid",
                    gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr 1fr"],
                    columnGap: "20px",
                  }),
              ...section.sx,
            }}
          >
            {section.fields.map((field, index) => (
              <div
                sx={{
                  breakInside: "avoid",
                  pageBreakInside: "avoid",
                  overflow: "hidden",
                  ...(field.fullWidth && { columnSpan: "all" }),
                  ...field.containerStyle,
                }}
                key={index}
              >
                <Field
                  {...{
                    field,
                    errors,
                    watchData,
                    register,
                    setValue,
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div
        sx={{
          display: "flex",
          justifyContent: type === "secondary" ? "flex-start" : "center",
          width: "100%",
        }}
      >
        <CTA disabled={submitting} sx={{ mt: "20px" }} type="submit">
          {submitting ? (
            <Spinner sx={{ height: "20px" }} />
          ) : (
            submitText || "Submit"
          )}
        </CTA>
      </div>
    </form>
  )
}

export default Form
