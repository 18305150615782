/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useVenueService } from "../../../services/venue-service"
import FilterOptionButton from "./filter-option-button"
import SpinnerSection from "../../spinner-section"

const LocationFilter = ({
  selectedNeighborhood,
  setSelectedNeighborhood,
  currentResults,
  ...restProps
}) => {
  const {
    getFields,
    response: { data: fields },
  } = useVenueService()
  React.useEffect(() => {
    if (!fields) getFields()
  }, [fields, getFields])
  const neighborhoods = React.useMemo(
    () =>
      fields &&
      fields
        .find(field => field.id === "neighborhood")
        .validations.find(validation => !!validation.in).in,
    [fields]
  )

  const toggleNeighborhood = name => {
    setSelectedNeighborhood(selected =>
      selected.includes(name)
        ? selected.filter(e => e !== name)
        : [...selected, name]
    )
  }
  return (
    <div {...restProps}>
      <div>
        {!neighborhoods ? (
          <SpinnerSection sx={{ height: "260px" }} />
        ) : (
          neighborhoods.map((neighborhood, index) => (
            <FilterOptionButton
              key={index}
              disabled={
                currentResults &&
                !currentResults.some(
                  result =>
                    result.fields.venue.fields.neighborhood &&
                    result.fields.venue.fields.neighborhood === neighborhood
                )
              }
              active={selectedNeighborhood.includes(neighborhood)}
              onClick={() => toggleNeighborhood(neighborhood)}
              sx={{
                mr: "5px",
                color: selectedNeighborhood.includes(neighborhood)
                  ? "primary"
                  : "body",
              }}
            >
              {neighborhood}
            </FilterOptionButton>
          ))
        )}
      </div>
    </div>
  )
}

export default LocationFilter
