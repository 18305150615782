/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { styles } from "../gatsby-plugin-theme-ui/index"
import ArrowIcon from "../images/carousel-chevron-down.svg"

const Select = React.forwardRef(({ children, ...restProps }, ref) => (
  <select
    sx={{
      ...styles.input,
      py: 0,
      display: "block",
      flexDirection: "row",
      backgroundImage: `url(${ArrowIcon})`,
      backgroundPosition: "95% 50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "20px",
    }}
    ref={ref}
    {...restProps}
  >
    {children}
  </select>
))
export default Select
