/** @jsx jsx */
import { jsx } from "theme-ui"
import { useDropzone } from "react-dropzone"
import CTA from "./cta"
const FileDropzone = ({ children, multiple, onDrop }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: "image/jpeg, image/png",
  })

  const files = acceptedFiles.length > 0 && (
    <div sx={{ mt: "12px" }}>
      {multiple && "Files:"}
      {acceptedFiles.map(file => (
        <div sx={{ fontStyle: "italic", textAlign: "center" }} key={file.path}>
          {file.path}
        </div>
      ))}
    </div>
  )

  return (
    <div className="container">
      <div
        sx={{
          border: "1px solid #c0c0c0",
          fontSize: "12px",
          padding: "20px",
          backgroundColor: "#f7f7f0",
          lineHeight: "120%",
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps({ multiple })} />
        {children}
        <div sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <CTA onClick={open} sx={{ minWidth: ["228px", "160px", "228px"] }}>
            Choose {multiple ? "Files" : "File"}
          </CTA>
        </div>
        {files}
      </div>
    </div>
  )
}
export default FileDropzone
