/** @jsx jsx */
import { jsx } from "theme-ui"
import ContentCard from "../content-card"
import Container from "../container"

const BasicPattern = ({ blade }) => {
  return (
    <Container>
      <ContentCard
        renderRootImage={false}
        card={{ ...blade, patternType: "Blade" }}
      />
      {blade.contentCards &&
        blade.contentCards.map(card => <ContentCard card={card} />)}
    </Container>
  )
}

export default BasicPattern
