/** @jsx jsx */
import { jsx } from "theme-ui"
import Checkbox from "../checkbox"
const FieldCheckbox = ({ field, register, validate }) => {
  return (
    <Checkbox
      name={field.name}
      ref={register({
        required: !!field.required,
        ...(validate && { validate }),
      })}
    >
      {field.text}
    </Checkbox>
  )
}
export default FieldCheckbox
