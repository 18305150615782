/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import RichText from "../rich-text"

const SubmissionPolicyPattern = ({ blade }) => {
  return (
    <Container sx={{ py: "50px" }}>
      <h6
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          color: "#2A2D2E",
          textTransform: "uppercase",
        }}
      >
        {blade.url}
      </h6>
      <h2
        sx={{
          fontSize: ["36px", "36px", "62px"],
        }}
      >
        {blade.title}
      </h2>
      <div sx={{ mx: "auto" }}>
        <RichText
          data={blade.body}
          textStyle={{
            fontSize: "18px",
            lineHeight: "1.3",
          }}
        />
      </div>
    </Container>
  )
}

export default SubmissionPolicyPattern
