/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import CarouselController from "./common/context"
import CarouselPagination from "./common/carousel-pagination"
import CarouselNavigation from "./common/carousel-navigation"
import CarouselSlider from "./common/carousel-slider"

const Carousel = ({ children }) => {
  return (
    <CarouselController totalSlides={React.Children.count(children)}>
      <div sx={{ position: "relative" }}>
        <CarouselSlider>{children}</CarouselSlider>

        <CarouselPagination
          triggerStyle={{
            mr: 1,
            mb: 0,
            ":last-child": { mr: 0 },
          }}
        />
        <div sx={{ display: "flex" }}>
          <CarouselNavigation previousControllerStyle={{ mr: 2 }} />
        </div>
      </div>
    </CarouselController>
  )
}

Carousel.propTypes = { children: PropTypes.node.isRequired }

export default Carousel
