/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import { useStaticQuery, graphql } from "gatsby"
import StoryCardMini from "../story-card-mini"
import RecentStories from "../recent-stories"
import StoryCardSimple from "../story-card-more"
import MoreStories from "../more-stories"
import Ad from "../ad"

const RecentStoriesPattern = ({
  blade,
  desktopAdUrl,
  mobileAdUrl,
  ...restProps
}) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        featuredStoriesSequence {
          orderItems {
            contentful_id
            author {
              name
            }
            title
            images {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulEditorialStory {
        editorialStories: nodes {
          contentful_id
          date
          title
          author {
            name
          }
          images {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const editorialStories = data.allContentfulEditorialStory.editorialStories
  const featuredStories = data.contentfulSite.featuredStoriesSequence.orderItems
  const filteredStories = editorialStories.filter(
    story =>
      !featuredStories.some(
        featuredStory => featuredStory.contentful_id === story.contentful_id
      )
  )

  return (
    <Container>
      <div
        sx={{
          mt: "20px",
        }}
      >
        <h5>Recent Stories</h5>
        <RecentStories stories={featuredStories} />

        <Ad
          desktopAdUrl={desktopAdUrl}
          mobileAdUrl={mobileAdUrl}
          sx={{ my: "40px" }}
        />
        {filteredStories.length > 0 && (
          <div sx={{ my: "25px" }}>
            <h5>More Stories</h5>
            <MoreStories stories={filteredStories} />
          </div>
        )}
      </div>
    </Container>
  )
}

export default RecentStoriesPattern
