/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import { TabsContext } from "./context"

const TabList = ({ titles, ...restProps }) => {
  const { activeIndex, setActiveIndex } = React.useContext(TabsContext)
  return (
    <React.Fragment>
      {titles.map((title, index) => (
        <button
          onClick={() => setActiveIndex(index)}
          key={index}
          className={index === activeIndex ? "active" : ""}
          sx={{
            borderWidth: "1px",
            borderRightWidth: 0,
            borderBottomWidth: 0,
            borderColor: "#ddd",
            borderStyle: "solid",
            transition: "0.2s",
            py: "5px",
            px: "10px",
            ":last-of-type": {
              borderRightWidth: "1px",
            },
            "&.active": {
              background: theme => theme.colors.primary,
              borderColor: theme => theme.colors.primary,
              color: "white",
            },
            ".active + &": { borderLeftColor: "white" },
            ":hover": {
              background: theme => theme.colors.secondary,
              borderColor: theme => theme.colors.secondary,
              color: "white",
            },
          }}
          {...restProps}
        >
          {title}
        </button>
      ))}
    </React.Fragment>
  )
}
TabList.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
}
export default TabList
