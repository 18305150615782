/** @jsx jsx */
import { jsx } from "theme-ui"
import Checkbox from "../checkbox"
const FieldCheckboxGroup = ({ register, field, watchData, setValue }) => {
  register(
    { name: field.name },
    {
      validate: {
        required: () => {
          if (!!field.required) {
            return !!(watchData[field.name] && watchData[field.name].length > 0)
          }
          return true
        },
      },
    }
  )
  const toggle = option => {
    const data = watchData[field.name]
    if (!data) {
      setValue(field.name, [option])
    } else {
      if (data.includes(option)) {
        setValue(
          field.name,
          data.filter(value => value !== option)
        )
      } else {
        setValue(field.name, [...data, option])
      }
    }
  }
  return (
    field.options && (
      <div
        sx={{
          ...(field.columns && {
            display: "grid",
            gridTemplateColumns: "max-content max-content",
            columnGap: ["10px", "20px", "40px"],
          }),
        }}
      >
        {field.options.map(option => (
          <Checkbox
            key={option}
            readOnly={true}
            name={`${field.name}.${option}`}
            onClick={() => toggle(option)}
            checked={!!watchData[field.name]?.includes(option)}
          >
            {option}
          </Checkbox>
        ))}
      </div>
    )
  )
}
export default FieldCheckboxGroup
