/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useDisciplineService } from "../services/discipline-service"
import Container from "./container"
import EventCarousel from "./event-carousel"

const Highlights = ({ title, ...restProps }) => {
  const sort = (a, b) => {
    const aStartDate = new Date(a.startDate)
    const aEndDate = new Date(a.endDate)
    const bStartDate = new Date(b.startDate)
    const bEndDate = new Date(b.endDate)
    if (aStartDate - bStartDate === 0) return aEndDate - bEndDate
    return aStartDate - bStartDate
  }
  const {
    findDisciplines,
    response: { data: disciplines },
  } = useDisciplineService()
  React.useEffect(() => {
    findDisciplines()
  }, [findDisciplines])
  const highlights = React.useMemo(
    () =>
      disciplines &&
      [].concat(
        ...disciplines
          .filter(
            discipline =>
              discipline &&
              discipline.highlights &&
              discipline.highlights.fields.eventList
          )
          .map(discipline =>
            discipline.highlights.fields.eventList
              .filter(event => {
                const ptEndDate = new Date(
                  event.fields.eventEndDate
                ).toLocaleString("en-US", { timeZone: "UTC" })
                const endDate = new Date(ptEndDate)
                endDate.setHours(0, 0, 0, 0)
                const ptNow = new Date().toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
                const now = new Date(ptNow)
                now.setHours(0, 0, 0, 0)
                return endDate >= now
              })
              .slice(0, 2)
          )
      ),
    [disciplines]
  )

  return highlights && highlights.length > 0 ? (
    <Container>
      <div sx={{ mb: "25px" }}>
        <h5 sx={{ fontSize: "30px", mb: "15px" }}>{title}</h5>
      </div>
      <EventCarousel events={highlights} collections={[]} sort={sort} />
    </Container>
  ) : null
}

export default Highlights
