/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import ContentCard from "../content-card"
import RichText from "../rich-text"
import { hasClass } from "../../helpers"

const TitleAboutPattern = ({ blade }) => {
  return (
    <Container
      showSeparator={true}
      outerStyle={{ backgroundColor: "backgrounds.primary" }}
      sx={{ py: "50px" }}
    >
      {blade.contentCards &&
        blade.contentCards.map((card, index) => (
          <h6
            key={index}
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              color: "#2A2D2E",
              textTransform: "uppercase",
            }}
          >
            {card.title}
          </h6>
        ))}

      <h2 sx={{ fontSize: ["36px", "36px", "62px"] }}>{blade.title}</h2>
      <div sx={{ width: ["100%", "100%", "75%"] }}>
        <RichText
          data={blade.body}
          textStyle={{
            fontSize: ["18px", "18px", "24px"],
            lineHeight: "1.3",
            color: "black",
          }}
        />
      </div>

      {blade.contentCards &&
        blade.contentCards.map((card, index) => (
          <div key={index} sx={{ display: "flex", justifyContent: "center" }}>
            {hasClass(card, "about-header-image") && (
              <ContentCard hideImage={false} card={card} />
            )}
          </div>
        ))}
    </Container>
  )
}

export default TitleAboutPattern
