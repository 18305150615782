import React from "react"
import PropTypes from "prop-types"

export const CarouselContext = React.createContext({})

const CarouselController = ({ children, totalSlides }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const state = React.useMemo(() => {
    return {
      activeIndex,
      setActiveIndex,
      totalSlides,
    }
  }, [activeIndex, totalSlides])

  return (
    <CarouselContext.Provider value={state}>
      {children}
    </CarouselContext.Provider>
  )
}

CarouselController.propTypes = {
  children: PropTypes.node.isRequired,
  totalSlides: PropTypes.number.isRequired,
}

export default CarouselController
