import React from "react"
import { useContentfulClient } from "../hooks/use-contentful-client"
import { graphql, useStaticQuery } from "gatsby"
import { useAPI } from "../hooks/use-api"

export const useVenueService = () => {
  const apiUrl = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
  `).site.siteMetadata.apiUrl
  const { getClient } = useContentfulClient()
  const [response, setResponse] = React.useState({
    data: null,
    loading: false,
    error: null,
  })
  const { get } = useAPI(setResponse)
  const getFields = React.useCallback(async () => {
    await get(`${apiUrl}/venues/fields`)
  }, [get, apiUrl])
  const checkNameIsUnique = React.useCallback(
    async name => {
      const entries = await getClient().getEntries({
        content_type: "venue",
        "fields.name": name,
      })
      return !entries.total
    },
    [getClient]
  )
  const findVenues = React.useCallback(
    async (partial, limit = 3) => {
      setResponse(response => ({ ...response, loading: true }))
      if (partial === null) {
        setResponse({
          data: [],
          loading: false,
        })
      } else
        try {
          const entries = await getClient().getEntries({
            content_type: "venue",
            "fields.name[match]": partial,
            limit,
          })
          setResponse({
            data: entries.items.map(item => item.fields),
            loading: false,
          })
        } catch (err) {
          setResponse(response => ({ ...response, loading: false, error: err }))
        }
    },
    [setResponse, getClient]
  )

  return {
    findVenues,
    checkNameIsUnique,
    getFields,
    response,
  }
}
