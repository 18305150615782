/** @jsx jsx */
import { jsx } from "theme-ui"
import { Router } from "@reach/router"
import EventFormView from "./event-form-view"
const EventForm = ({ ...restProps }) => {
  return (
    <Router {...restProps}>
      <EventFormView path="/event-submission/:name/:secret" />
    </Router>
  )
}

export default EventForm
