/** @jsx jsx */
import { jsx } from "theme-ui"
import Carousel from "../carousel"
import Hero from "../hero"
import Container from "../container"
import ContentCard from "../content-card"

const CarouselPattern = ({ blade }) => {
  return (
    <Container>
      <ContentCard card={{ ...blade, patternType: "Blade" }} />
      <Carousel>
        {blade.contentCards.map((card, index) => (
          <Hero key={index} desktopImage={card.desktopImage}>
            <ContentCard card={card} renderRootImage={false} />
          </Hero>
        ))}
      </Carousel>
    </Container>
  )
}

export default CarouselPattern
