/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import DatePickerComponent, { CalendarContainer } from "react-datepicker"
import { styles } from "../gatsby-plugin-theme-ui"
import arrowIcon from "../images/carousel-chevron-R.svg"
import CalendarIcon from "../images/calendarBlack.svg"
const DatePicker = ({ minDate, date, onChange }) => {
  const today = new Date()
  const Container = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div
          sx={{
            position: "relative",
            zIndex: 1,
            backgroundColor: "white",
            py: "20px",
            px: "15px",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
            display: "flex",
            ".react-datepicker": {
              "&__day, &__day-name": {
                mx: "3px",
                my: "1px",
                fontSize: "13px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              "&__week, &__day-names": {
                display: "flex",
              },
              "&__month": {},
              "&__day": {
                cursor: "pointer",
                borderRadius: "100%",
                ":hover": {
                  backgroundColor: "backgrounds.secondary",
                },
                "&--disabled": {
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                  cursor: "default",
                  color: "#c0c0c0",
                },
                "&--selected": {
                  color: "white",
                  ":hover": {
                    backgroundColor: "secondary",
                  },
                  backgroundColor: "primary",
                },
              },
              "&__day-name": {
                display: "block",
                textAlign: "center",
                fontWeight: 700,
                fontSize: 0,

                "&::first-letter": {
                  fontSize: "13px",
                  ml: "7px",
                },
              },
              "&__current-month": {
                fontSize: "15px",
                fontWeight: 600,
              },
              "&__navigation--next": {
                order: 3,
              },
            },
          }}
        >
          {children}
        </div>
      </CalendarContainer>
    )
  }
  return (
    <div sx={{ ".react-datepicker-popper": { zIndex: 1 } }}>
      <DatePickerComponent
        sx={{
          ...styles.input,
          width: "100%",
          zIndex: 99,
          backgroundImage: `url(${CalendarIcon})`,
          backgroundPosition: "95% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "20px",
        }}
        minDate={minDate || today}
        selected={date}
        forceShowMonthNavigation={true}
        nextMonthButtonLabel={
          <img alt="" sx={{ height: "33px", ml: "5px" }} src={arrowIcon} />
        }
        previousMonthButtonLabel={
          <img
            alt=""
            sx={{
              height: "33px",
              transform: "rotate(180deg)",
              mr: "5px",
            }}
            src={arrowIcon}
          />
        }
        onChange={date => {
          onChange(date)
        }}
        calendarContainer={Container}
        useWeekdaysShort={true}
      />
    </div>
  )
}
export default DatePicker
