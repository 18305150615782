/** @jsx jsx */
import { jsx } from "theme-ui"
import Accent from "../accent"
import ContentCard from "../content-card"
import RichText from "../rich-text"

const AccentPattern = ({ blade }) => {
  return (
    <Accent
      colorB="primary"
      sx={{ display: "flex", justifyContent: "center", pb: 0, padding: "30px" }}
    >
      <div sx={{ maxWidth: "587px" }}>
        <h5>{blade.title}</h5>
        <RichText
          sx={{ "a:only-of-type": { color: "white" } }}
          data={blade.body}
        />
        {blade.contentCards &&
          blade.contentCards.map(card => <ContentCard card={card} />)}
      </div>
    </Accent>
  )
}

export default AccentPattern
