/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import Accordion from "../accordion"
import ContentCard from "../content-card"

const AccordionPattern = ({ blade }) => {
  return (
    <Container>
      <ContentCard card={{ ...blade, patternType: "Blade" }} />
      <Accordion
        items={blade.contentCards.map(card => ({
          title: card.title,
          content: <ContentCard card={{ ...card, title: null }} />,
        }))}
      />
    </Container>
  )
}

export default AccordionPattern
