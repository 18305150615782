/** @jsx jsx */
import { jsx } from "theme-ui"
import FeaturedStoryHero from "../featured-story-header"
import ContentCard from "../content-card"

const FeaturedStoryHeroPattern = ({ blade }) => {
  return <FeaturedStoryHero />
}

export default FeaturedStoryHeroPattern
