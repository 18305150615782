/** @jsx jsx */
import { jsx } from "theme-ui"
import Select from "../select"
const FieldSelect = ({ field, isDisabled, register, validate }) => {
  return (
    <Select
      name={field.name}
      disabled={isDisabled}
      ref={register({
        required: !!field.required,
        validate: {
          required: value => value !== "_",
          ...validate,
        },
      })}
      defaultValue=""
      sx={{
        width: "100%",
        py: "11px",
        display: "flex",
        zIndex: 99,
      }}
    >
      <option value="" disabled={field.required}>
        {field.required ? "Select One" : ""}
      </option>
      {field.options &&
        field.options.map(option => <option key={option}>{option}</option>)}
    </Select>
  )
}
export default FieldSelect
