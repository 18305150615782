/** @jsx jsx */
import { jsx } from "theme-ui"
import EventList from "../event-list"

const Results = ({ results, desktopAdUrl, mobileAdUrl, ...restProps }) => {
  const sort = (a, b) => {
    const aStartDate = new Date(a.fields.eventStartDate)
    const aEndDate = new Date(a.fields.eventEndDate)
    const bStartDate = new Date(b.fields.eventStartDate)
    const bEndDate = new Date(b.fields.eventEndDate)
    if (aStartDate - bStartDate === 0) return aEndDate - bEndDate
    return aStartDate - bStartDate
  }
  const orderedResults = results.sort(sort)
  return (
    <div>
      <EventList
        events={orderedResults}
        defaultShowAll={true}
        collections={[]}
        {...{ desktopAdUrl, mobileAdUrl }}
      />
    </div>
  )
}

export default Results
