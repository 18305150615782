/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import EventCarousel from "../event-carousel"
import { useEventCalendarEvents } from "../../hooks/use-event-calendar-events"
import { useEventCalendarCollections } from "../../hooks/use-event-calendar-collections"
import EventList from "../event-list"
import SpinnerSection from "../spinner-section"
import Ad from "../ad"

const Calendar = ({ filters, desktopAdUrl, mobileAdUrl, ...restProps }) => {
  const {
    timeFilteredTodayEvents,
    weekEvents,
    monthEvents,
    groupedFutureEvents,
    loading,
  } = useEventCalendarEvents()
  const {
    todayCollections,
    weekCollections,
    monthCollections,
    futureCollections,
  } = useEventCalendarCollections(
    timeFilteredTodayEvents,
    weekEvents,
    monthEvents,
    groupedFutureEvents
  )
  return !(
    timeFilteredTodayEvents &&
    weekEvents &&
    monthEvents &&
    groupedFutureEvents &&
    !loading
  ) ? (
    <SpinnerSection />
  ) : (
    <div sx={{ "&>div": { mb: "50px", "&:last-of-type": { mb: 0 } } }}>
      {timeFilteredTodayEvents && timeFilteredTodayEvents.length > 0 && (
        <EventCarousel
          events={timeFilteredTodayEvents}
          collections={todayCollections}
          title={"Today"}
        />
      )}
      {weekEvents && weekEvents.length > 0 && (
        <EventCarousel
          events={weekEvents}
          collections={weekCollections}
          title={"This Week"}
        />
      )}
      <Ad {...{ desktopAdUrl, mobileAdUrl }} />
      {monthEvents && monthEvents.length > 0 && (
        <React.Fragment>
          <EventList
            events={monthEvents}
            collections={monthCollections}
            title={"This Month"}
          />
          <Ad {...{ desktopAdUrl, mobileAdUrl }} />
        </React.Fragment>
      )}
      {groupedFutureEvents &&
        groupedFutureEvents.length > 0 &&
        groupedFutureEvents.map(
          (month, index) =>
            month.events &&
            month.events.length > 0 && (
              <React.Fragment>
                {index % 2 === 0 && index !== 0 && (
                  <Ad {...{ desktopAdUrl, mobileAdUrl }} />
                )}
                <EventList
                  key={index}
                  events={month.events}
                  collections={futureCollections[month.title]}
                  title={month.title}
                />
              </React.Fragment>
            )
        )}
    </div>
  )
}

export default Calendar
