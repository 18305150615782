/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useLayoutEffect } from "react"
import CTA from "../cta"
import { useStaticQuery, graphql } from "gatsby"

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = "hidden"
    return () => (document.body.style.overflow = originalStyle)
  }, [])
}

const FilterContainer = ({ children, onApply, onClear, ...restProps }) => {
  useLockBodyScroll()

  const { experiences, disciplines } = useStaticQuery(graphql`
    query {
      experiences: allContentfulExperience(sort: { fields: experienceName }) {
        nodes {
          experienceName
          contentful_id
        }
      }
      disciplines: allContentfulDiscipline(sort: { fields: disciplineName }) {
        nodes {
          disciplineName
          contentful_id
        }
      }
    }
  `)

  return (
    <div
      sx={{
        maxHeight: "100%",
        background: "white",
        pt: "20px",
        px: ["20px", "20px", "36px", "25px"],
        boxShadow: ["none", "none", "none", "0px 0px 5px rgba(0,0,0,0.4)"],
        display: "flex",
        flexDirection: "column",
      }}
      {...restProps}
    >
      <div
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          borderBottom: "1px solid #c0c0c0",
        }}
      >
        {children}
      </div>
      <div
        sx={{
          py: "20px",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CTA
          sx={{
            p: 0,
            minWidth: "unset",
            border: "0",
            background: "transparent",
            color: "primary",
            ":hover": {
              background: "transparent",
              color: "primary",
            },
            cursor: "pointer",
          }}
          onClick={onClear}
        >
          Clear All
        </CTA>
        <CTA
          sx={{ minWidth: "unset", width: "120px", cursor: "pointer" }}
          onClick={onApply}
        >
          Done
        </CTA>
      </div>
    </div>
  )
}

export default FilterContainer
