/** @jsx jsx */
import { jsx } from "theme-ui"
import BasicCard from "./basic-card"
import CTACard from "./cta-card"
import ColumnLayoutCard from "./column-layout-card"
import RowLayoutCard from "./row-layout-card"

const Card = ({ content, ...restProps }) => {
  const renderers = {
    BasicCard: <BasicCard content={content} {...restProps} />,
    CTA: <CTACard content={content} {...restProps} />,
    Blade: <BasicCard content={content} {...restProps} />,
    ColumnLayout: <ColumnLayoutCard content={content} {...restProps} />,
    RowLayout: <RowLayoutCard content={content} {...restProps} />,
  }
  return renderers[content.patternType] || renderers.BasicCard
}

export default Card
