/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import AutosuggestComponent from "react-autosuggest"
import { styles } from "../gatsby-plugin-theme-ui/index"
const Autosuggest = ({
  placeholder,
  suggestions,
  render,
  fetch,
  clear,
  getValue,
  onSelect,
  showAdd,
  addText,
  onAdd,
  shouldRenderSuggestions,
  freeInput,
  initialValue,
  hideTick,
}) => {
  const [value, setValue] = React.useState("")
  const [correctValue, setCorrectValue] = React.useState("")
  const onChange = (e, { newValue }) => {
    if (!newValue) {
      setCorrectValue("")
      setValue("")
      onSelect("")
    } else {
      setValue(newValue)
    }
  }
  const onBlur = e => {
    if (!e.target.value) {
      setCorrectValue("")
      setValue("")
    } else {
      if (freeInput) onSelect(e.target.value)
      setValue(freeInput ? e.target.value : correctValue)
    }
  }
  React.useEffect(() => {
    if (
      (initialValue || initialValue === null) &&
      initialValue !== correctValue
    ) {
      setValue(initialValue || "")
      setCorrectValue(initialValue || "")
    }
  }, [initialValue, correctValue, setCorrectValue])
  return (
    <div
      sx={{
        ul: { listStyleType: "none", p: 0, m: 0 },
        input: {
          ...styles.input,
          width: "100%",
          pr: "45px",
          "&+div": { display: "none" },
          "&:focus+div": { display: "block" },
        },
        li: {
          mb: 0,
        },
        position: "relative",
      }}
    >
      <AutosuggestComponent
        suggestions={suggestions}
        onSuggestionsFetchRequested={fetch}
        onSuggestionsClearRequested={clear}
        onSuggestionSelected={(event, { suggestionValue }) => {
          setCorrectValue(suggestionValue)
          onSelect(suggestionValue)
        }}
        getSuggestionValue={getValue}
        renderSuggestion={value => (
          <div
            sx={{
              cursor: "pointer",
              p: "10px",
              ":hover": {
                backgroundColor: "backgrounds.secondary",
              },
            }}
          >
            {render(value)}
          </div>
        )}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={{
          value: value || "",
          placeholder: placeholder || "",
          onChange,
          onBlur,
        }}
        renderSuggestionsContainer={({ containerProps, children }) => {
          return (
            <div
              sx={{
                ...(freeInput
                  ? { display: "none!important" }
                  : children && { display: "block!important" }),
                boxShadow: "0px 1px 5px -1px rgba(0,0,0,0.2)",
                py: "5px",
                background: "white",
                ":hover": { display: "block!important" },
              }}
              {...containerProps}
            >
              {children && children}
              {!correctValue && showAdd && (
                <div
                  sx={{
                    p: "10px",
                    color: "primary",
                    ":hover": {
                      backgroundColor: "backgrounds.secondary",
                      cursor: "pointer",
                    },
                  }}
                  onClick={onAdd}
                >
                  {addText}
                </div>
              )}
            </div>
          )
        }}
      />
      <div
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "46px",
          pr: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {freeInput ? (
          <div
            sx={{
              fontFamily: "Fabriga",
              fontWeight: 500,
              fontSize: "30px",
              color: "primary",
            }}
          >
            +
          </div>
        ) : (
          correctValue &&
          !hideTick && (
            <svg
              width="20"
              height="20"
              viewBox="3 3 18 18"
              sx={{
                height: "20px",
                width: "20px",
                borderRadius: "100%",
                border: "2px solid #44dd44",
                path: {
                  width: "100%",
                  height: "100%",
                  fill: "#44dd44",
                },
              }}
            >
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
          )
        )}
      </div>
    </div>
  )
}
export default Autosuggest
