import React from "react"
import querystring from "query-string"
import { useIsClient } from "../../../../hooks/use-is-client"
import { useLocation } from "react-use"

const isInDateRange = (event, startDate, endDate) => {
  const ptEventStartDate = new Date(
    event.fields.eventStartDate
  ).toLocaleString("en-US", { timeZone: "UTC" })
  const eventStartDate = new Date(ptEventStartDate)
  const ptEventEndDate = new Date(
    event.fields.eventEndDate
  ).toLocaleString("en-US", { timeZone: "UTC" })
  const eventEndDate = new Date(ptEventEndDate)
  const ptParamStartDate = new Date(startDate).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  })
  const paramStartDate = new Date(ptParamStartDate)
  const ptParamEndDate = new Date(endDate).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  })
  const paramEndDate = new Date(ptParamEndDate)
  const startClause = startDate ? eventEndDate >= paramStartDate : true
  const endClause = endDate ? eventStartDate <= paramEndDate : true
  return startClause && endClause
}
const getSetter = (set, key) => value =>
  set(state => ({
    ...state,
    [key]: typeof value === "function" ? value(state[key]) : value,
  }))

export const useFilters = setFilter => {
  const [selectedState, setSelectedState] = React.useState({
    selectedStartDate: null,
    selectedEndDate: null,
    selectedExperience: [],
    selectedDiscipline: [],
    selectedNeighborhood: [],
    selectedPriceRange: {
      min: 0,
      max: 500,
      enabled: false,
    },
    selectedMoreOptions: [],
    selectedVenue: null,
    searchedText: null,
    initializedQuery: false,
  })
  const {
    selectedExperience,
    selectedDiscipline,
    selectedNeighborhood,
    selectedStartDate,
    selectedEndDate,
    selectedPriceRange,
    selectedMoreOptions,
    selectedVenue,
    searchedText,
    initializedQuery,
  } = React.useMemo(() => selectedState, [selectedState])
  const setSelectedExperience = React.useCallback(
    getSetter(setSelectedState, "selectedExperience"),
    [setSelectedState]
  )
  const setSelectedDiscipline = React.useCallback(
    getSetter(setSelectedState, "selectedDiscipline"),

    [setSelectedState]
  )
  const setSelectedNeighborhood = React.useCallback(
    getSetter(setSelectedState, "selectedNeighborhood"),
    [setSelectedState]
  )
  const setSelectedStartDate = React.useCallback(
    getSetter(setSelectedState, "selectedStartDate"),

    [setSelectedState]
  )
  const setSelectedEndDate = React.useCallback(
    getSetter(setSelectedState, "selectedEndDate"),
    [setSelectedState]
  )
  const setSelectedPriceRange = React.useCallback(
    getSetter(setSelectedState, "selectedPriceRange"),
    [setSelectedState]
  )
  const setSelectedMoreOptions = React.useCallback(
    getSetter(setSelectedState, "selectedMoreOptions"),
    [setSelectedState]
  )
  const setSelectedVenue = React.useCallback(
    getSetter(setSelectedState, "selectedVenue"),
    [setSelectedState]
  )

  const setSearchedText = React.useCallback(
    getSetter(setSelectedState, "searchedText"),
    [setSelectedState]
  )

  const { search } = useLocation()

  const internalQueryString = React.useMemo(
    () =>
      querystring.stringify({
        ...(selectedState.selectedExperience &&
          selectedState.selectedExperience.length > 0 && {
            experiences: selectedState.selectedExperience.join(","),
          }),
        ...(selectedState.selectedDiscipline &&
          selectedState.selectedDiscipline.length > 0 && {
            discipline: selectedState.selectedDiscipline,
          }),
        ...(selectedState.selectedNeighborhood &&
          selectedState.selectedNeighborhood.length > 0 && {
            neighborhood: selectedState.selectedNeighborhood,
          }),
        ...(selectedState.selectedStartDate && {
          "start-date":
            selectedState.selectedStartDate.getFullYear() +
            "-" +
            (selectedState.selectedStartDate.getMonth() + 1) +
            "-" +
            selectedState.selectedStartDate.getDate(),
        }),
        ...(selectedState.selectedEndDate && {
          "end-date":
            selectedState.selectedEndDate.getFullYear() +
            "-" +
            (selectedState.selectedEndDate.getMonth() + 1) +
            "-" +
            selectedState.selectedEndDate.getDate(),
        }),
        ...(selectedState.selectedPriceRange &&
          selectedState.selectedPriceRange.enabled &&
          !isNaN(selectedState.selectedPriceRange.min) &&
          !isNaN(selectedState.selectedPriceRange.max) && {
            "min-price": selectedState.selectedPriceRange.min,
            "max-price": selectedState.selectedPriceRange.max,
          }),
        ...(selectedState.selectedMoreOptions &&
          selectedState.selectedMoreOptions.length > 0 && {
            "more-options": selectedState.selectedMoreOptions.join(","),
          }),
        ...(selectedState.selectedVenue && {
          venue: selectedState.selectedVenue,
        }),
        ...(selectedState.searchedText && {
          "searched-text": selectedState.searchedText,
        }),
      }),
    [selectedState]
  )

  const moreOptions = React.useMemo(
    () => ({
      "Free Events": event => event.fields.pricingType === "Free",
      "Donation Based": event => event.fields.pricingType === "Donation Based",
      "Student Discount": event =>
        event.fields.discountAvailable &&
        event.fields.pricingType === "Custom" &&
        event.fields.discountAvailable.includes("Student"),
      "Senior Discount": event =>
        event.fields.discountAvailable &&
        event.fields.pricingType === "Custom" &&
        event.fields.discountAvailable.includes("Senior"),
      "Child Discount": event =>
        event.fields.discountAvailable &&
        event.fields.pricingType === "Custom" &&
        event.fields.discountAvailable.includes("Child"),
    }),
    []
  )

  const isClient = useIsClient()

  React.useEffect(() => {
    if (isClient) {
      const query = querystring.parse(search)
      let startDate = null
      if (query["start-date"]) {
        const [yyyy, mm, dd] = query["start-date"].split("-")
        startDate = new Date(yyyy, mm - 1, dd)
      }
      let endDate = null
      if (query["end-date"]) {
        const [yyyy, mm, dd] = query["end-date"].split("-")
        endDate = new Date(yyyy, mm - 1, dd)
      }
      let priceRange = { min: 0, max: 500, enabled: false }
      if (query["min-price"] && query["max-price"]) {
        priceRange = {
          min: parseInt(query["min-price"]),
          max: parseInt(query["max-price"]),
          enabled: true,
        }
      }
      setSelectedState(state => ({
        selectedExperience: query.experiences
          ? query.experiences.split(",")
          : [],
        selectedDiscipline: query.discipline ? [query.discipline] : [],
        selectedNeighborhood: query.neighborhood ? [query.neighborhood] : [],
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        selectedPriceRange: priceRange,
        selectedMoreOptions: query["more-options"]
          ? query["more-options"].split(",")
          : [],
        selectedVenue: query.venue ? query.venue : null,
        searchedText: query["searched-text"],
        initializedQuery: true,
      }))
    }
  }, [search, isClient, setSelectedState])
  React.useEffect(() => {
    setFilter(() => {
      if (
        !(
          selectedState.selectedExperience.length > 0 ||
          selectedState.selectedDiscipline.length > 0 ||
          selectedState.selectedNeighborhood.length > 0 ||
          selectedState.selectedStartDate ||
          selectedState.selectedEndDate ||
          (selectedState.selectedPriceRange.enabled &&
            !isNaN(selectedState.selectedPriceRange.min) &&
            !isNaN(selectedState.selectedPriceRange.max)) ||
          selectedState.selectedMoreOptions.length > 0 ||
          selectedState.selectedVenue ||
          selectedState.searchedText
        )
      ) {
        return null
      } else {
        return event => {
          let isResult = true

          if (selectedState.selectedExperience.length > 0) {
            isResult =
              isResult &
              selectedState.selectedExperience.every(
                selected =>
                  event.fields.experience &&
                  event.fields.experience.some(
                    experience =>
                      experience.fields &&
                      experience.fields.experienceName === selected
                  )
              )
          }
          if (selectedState.selectedDiscipline.length > 0) {
            isResult =
              isResult &
              selectedState.selectedDiscipline.every(
                selected =>
                  event.fields.discipline.fields.disciplineName === selected
              )
          }
          if (selectedState.selectedNeighborhood.length > 0) {
            isResult =
              isResult &
              selectedState.selectedNeighborhood.every(
                selected =>
                  event.fields.venue &&
                  event.fields.venue.fields &&
                  event.fields.venue.fields.neighborhood === selected
              )
          }
          if (
            selectedState.selectedStartDate ||
            selectedState.selectedEndDate
          ) {
            isResult =
              isResult &
              isInDateRange(
                event,
                selectedState.selectedStartDate,
                selectedState.selectedEndDate
              )
          }
          if (
            selectedState.selectedPriceRange.enabled &&
            !isNaN(selectedState.selectedPriceRange.min) &&
            !isNaN(selectedState.selectedPriceRange.max)
          ) {
            isResult =
              isResult &
              ((event.fields.pricingType === "Custom" &&
                event.fields.minPrice <= selectedState.selectedPriceRange.max &&
                event.fields.maxPrice >=
                  selectedState.selectedPriceRange.min) ||
                (selectedState.selectedPriceRange.min === 0 &&
                  event.fields.pricingType === "Free"))
          }
          if (selectedState.selectedMoreOptions.length > 0) {
            isResult =
              isResult &
              selectedState.selectedMoreOptions.every(option =>
                moreOptions[option](event)
              )
          }
          if (selectedState.selectedVenue) {
            isResult =
              isResult &
              (event.fields.venue.fields.name === selectedState.selectedVenue)
          }
          if (selectedState.searchedText) {
            isResult =
              isResult &&
              (event.fields.venue?.fields.name
                ?.toLowerCase()
                .includes(selectedState.searchedText.toLowerCase()) ||
                event.fields.organization?.fields.name
                  ?.toLowerCase()
                  .includes(selectedState.searchedText.toLowerCase()) ||
                event.fields.eventName
                  ?.toLowerCase()
                  .includes(selectedState.searchedText.toLowerCase()))
          }
          return isResult
        }
      }
    })
  }, [selectedState, moreOptions])
  React.useEffect(() => {
    if (initializedQuery) {
      window.history.pushState(
        "page2",
        document.title,
        `${window.location.pathname}${
          internalQueryString.length > 0 ? "?" + internalQueryString : ""
        }`
      )
    }
  }, [internalQueryString, initializedQuery])
  return {
    selectedExperience,
    setSelectedExperience,
    selectedDiscipline,
    setSelectedDiscipline,
    selectedNeighborhood,
    setSelectedNeighborhood,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedPriceRange,
    setSelectedPriceRange,
    moreOptions,
    selectedMoreOptions,
    setSelectedMoreOptions,
    selectedVenue,
    setSelectedVenue,
    searchedText,
    setSearchedText,
  }
}
