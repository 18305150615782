/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useDisciplineService } from "../../services/discipline-service"
import EventCardMini from "../event-card-mini"
import Container from "../container"
import Ad from "../ad"

const Highlights = ({
  selectedDiscipline,
  desktopAdUrl,
  mobileAdUrl,
  ...restProps
}) => {
  const {
    findDisciplines,
    response: { data: disciplines },
  } = useDisciplineService()
  React.useEffect(() => {
    selectedDiscipline &&
      findDisciplines({ "fields.disciplineName": selectedDiscipline })
  }, [selectedDiscipline, findDisciplines])
  const discipline = disciplines && disciplines[0]
  const filteredHighlights = React.useMemo(
    () =>
      discipline?.highlights?.fields?.eventList &&
      discipline.highlights.fields.eventList.filter(event => {
        const ptEndDate = new Date(
          event.fields.eventEndDate
        ).toLocaleString("en-US", { timeZone: "UTC" })
        const endDate = new Date(ptEndDate)
        const ptNow = new Date().toLocaleString("en-US", {
          timeZone: "America/Los_Angeles",
        })
        const now = new Date(ptNow)
        return endDate >= now
      }),
    [discipline]
  )
  return (
    <React.Fragment>
      {filteredHighlights && filteredHighlights.length > 0 ? (
        <div sx={{ backgroundColor: "rgb(255,255,255)" }}>
          <Container
            sx={{
              px: ["37px", "37px", "36px", "65px"],
            }}
          >
            <h5>/Highlights - {discipline.disciplineName}</h5>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: [
                  "1fr",
                  "1fr",
                  "1fr 1fr",
                  "1fr 1fr",
                  "1fr 1fr 1fr",
                ],
                gap: "20px",
              }}
            >
              {filteredHighlights.map((event, index) => (
                <EventCardMini
                  key={index}
                  type="highlights"
                  event={{
                    ...event.fields,
                    contentful_id: event.sys.id,
                    organization: {
                      name: event.fields.organization.fields.name,
                    },
                    discipline: {
                      disciplineName:
                        event.fields.discipline?.fields.disciplineName,
                    },
                    eventImage: {
                      file: { url: event.fields.eventImage?.fields.file.url },
                    },
                  }}
                />
              ))}
            </div>
            {discipline.highlights.fields.curator && (
              <div
                sx={{
                  maxWidth: "600px",
                  display: "grid",
                  gridTemplateColumns: "max-content 1fr",
                  columnGap: "35px",
                  mt: "50px",
                }}
              >
                <img
                  src={
                    discipline.highlights.fields.curator.fields.image.fields
                      .file.url + "?w=125&h=125"
                  }
                />
                <div sx={{ py: "10px" }}>
                  <h6>
                    Curated by{" "}
                    {discipline.highlights.fields.curator.fields.name}
                  </h6>
                  <div>
                    {discipline.highlights.fields.curator.fields.description}
                  </div>
                </div>
              </div>
            )}
          </Container>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default Highlights
