/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useVenueService } from "../../../services/venue-service"
import FilterOptionButton from "./filter-option-button"
import Autosuggest from "../../autosuggest"

const VenuesFilter = ({
  selectedVenue,
  setSelectedVenue,
  currentResults,
  ...restProps
}) => {
  const {
    findVenues,
    response: { data: venues },
  } = useVenueService()
  const filteredVenues = React.useMemo(
    () =>
      venues &&
      venues.filter(venue =>
        currentResults.some(
          result => result.fields.venue.fields.name === venue.name
        )
      ),
    [currentResults, venues]
  )
  return (
    <div {...restProps}>
      <h5
        sx={{
          fontSize: "18px",
          borderBottom: "1px solid #c0c0c0",
          pb: "5px",
          mb: "20px",
        }}
      >
        By Venue
      </h5>
      <div sx={{ fontSize: "13px", color: "swatches.secondary.darkTan" }}>
        Venue Name
      </div>
      <div
        sx={{
          minHeight: ["75px", "75px", "75px", "75px", "75px", "95px"],
          mb: "10px",
        }}
      >
        <Autosuggest
          {...{
            suggestions: filteredVenues || [],
            render: venue => (
              <React.Fragment>
                {venue.name}{" "}
                <span
                  sx={{ fontSize: "13px", color: "swatches.secondary.darkTan" }}
                >
                  {venue.neighborhood},{venue.city}
                </span>
              </React.Fragment>
            ),
            fetch: ({ value }) => {
              if (value.length > 1) findVenues(value)
            },
            clear: () => findVenues(null),
            getValue: venue => venue.name,
            onSelect: venueName => setSelectedVenue(venueName),
            showAdd: false,
            shouldRenderSuggestions: () => true,
            freeInput: false,
            hideTick: true,
            initialValue: selectedVenue,
          }}
        />
      </div>
    </div>
  )
}

export default VenuesFilter
