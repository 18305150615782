/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Checkbox as CheckboxThemeUI, Label } from "@theme-ui/components"

const Checkbox = React.forwardRef(({ children, ...restProps }, ref) => (
  <Label sx={{ mb: "10px" }}>
    <CheckboxThemeUI
      sx={{
        height: "24px",
        background: "transparent!important",
      }}
      ref={ref}
      {...restProps}
    />
    <div
      sx={{
        mt: "-2px",
      }}
    >
      {children}
    </div>
  </Label>
))
export default Checkbox
