/** @jsx jsx */
import { jsx } from "theme-ui"
import { Radio as RadioThemeUI, Label } from "@theme-ui/components"
import React from "react"

const Radio = React.forwardRef(({ children, ...restProps }, ref) => (
  <Label sx={{ mb: "10px" }}>
    <RadioThemeUI
      sx={{
        height: "24px",
        background: "transparent!important",
      }}
      ref={ref}
      {...restProps}
    />
    <div
      sx={{
        mt: "-2px",
      }}
    >
      {children}
    </div>
  </Label>
))
export default Radio
