/** @jsx jsx */
import { jsx } from "theme-ui"
import Input from "../input"
const FieldEmail = ({ field, register, validate, fieldError }) => {
  return (
    <Input
      name={field.name}
      ref={register({
        required: !!field.required,
        ...(validate && { validate }),
      })}
      sx={{
        width: "100%",
        ...(fieldError && { borderColor: "primary" }),
      }}
    />
  )
}
export default FieldEmail
