/** @jsx jsx */
import { jsx } from "theme-ui"
import Autosuggest from "../autosuggest"
const FieldAutosuggest = ({
  register,
  field,
  validate,
  setValue,
  watchData,
}) => {
  register(
    {
      name: field.name,
    },
    {
      validate,
    }
  )
  register({
    name: `${field.name}-isNew`,
  })
  const onSelect = newValue => {
    if (!newValue) {
      setValue(`${field.name}-isNew`, false, { shouldDirty: true })
    }
    setValue(field.name, newValue, { shouldDirty: true })
  }
  const onAdd = () => {
    setValue(`${field.name}-isNew`, true, { shouldDirty: true })
  }
  return (
    <div>
      <Autosuggest
        suggestions={field.suggestions}
        shouldRenderSuggestions={field.shouldRenderSuggestions}
        render={field.render}
        value={watchData[field.name]}
        fetch={field.fetch}
        clear={field.clear}
        getValue={field.getValue}
        onSelect={onSelect}
        placeholder={field.placeholder}
        showAdd={true}
        addText={field.addText}
        onAdd={onAdd}
        freeInput={watchData[`${field.name}-isNew`]}
      />
    </div>
  )
}
export default FieldAutosuggest
