/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useVenueService } from "../../../services/venue-service"
import FilterOptionButton from "./filter-option-button"
import Autosuggest from "../../autosuggest"
import Container from "../../container"
import ctaArrow from "../../../images/cta-arrow.svg"
import magnifyingGlass from "../../../images/filter-search.svg"

const SearchBar = ({
  searchedText,
  setSearchedText,
  scrolled,
  ...restProps
}) => {
  const [internalValue, setInternalValue] = React.useState(null)
  React.useEffect(() => {
    setInternalValue(searchedText)
  }, [searchedText, setInternalValue])
  const onSearch = () => setSearchedText(internalValue)
  const onChange = event => setInternalValue(event.target.value)
  const onKeyPress = event => {
    if (event.charCode === 13) onSearch()
  }
  return (
    <div
      sx={{
        backgroundColor: searchedText
          ? "white"
          : [
              "swatches.secondary.lightTan",
              "swatches.secondary.lightTan",
              "white",
            ],
      }}
      {...restProps}
    >
      <Container sx={{ py: [0] }}>
        {searchedText ? (
          <div
            sx={{
              height: ["60px"],
              my: scrolled ? "5px" : "30px",
              mx: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span sx={{ fontSize: ["16px", "16px", "30px"] }}>
              Search Results for{" "}
              <span sx={{ fontWeight: 600 }}>“{searchedText}”</span>
            </span>
            <button
              onClick={() => setSearchedText(null)}
              sx={{
                fontSize: "14px",
                color: "primary",
                cursor: "pointer",
                py: "10px",
              }}
            >
              <span sx={{ display: ["none", "none", "inline"] }}>
                Clear Search
              </span>
              <span sx={{ ml: "10px", fontWeight: "600" }}>&#10005;</span>
            </button>
          </div>
        ) : (
          <div
            sx={{
              background: "white",
              height: ["46px", "46px", "60px"],
              mt: [0, 0, "30px"],
              mb: ["20px", "20px", "30px"],
              mx: "auto",
              maxWidth: "735px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: theme => [
                0,
                0,
                `1px solid ${theme.colors.primary}`,
              ],
            }}
          >
            <input
              value={internalValue || ""}
              onChange={onChange}
              onKeyPress={onKeyPress}
              placeholder="Search for Events Using Keywords"
              sx={{
                fontSize: ["13px", "13px", "24px"],
                p: "14px",
                flexGrow: 1,
                color: "#4A4A4A",
                border: 0,
              }}
            />
            <button
              onClick={onSearch}
              sx={{
                height: "38px",
                px: ["14px", "14px", "8px"],
                py: ["11px", "11px", "8px"],
                cursor: "pointer",
              }}
            >
              <img
                sx={{ height: "22px", display: ["none", "none", "block"] }}
                src={ctaArrow}
              />
              <img
                sx={{ height: "18px", display: ["block", "block", "none"] }}
                src={magnifyingGlass}
              />
            </button>
          </div>
        )}
      </Container>
    </div>
  )
}

export default SearchBar
