/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FilterOptionButton from "./filter-option-button"

const EventTypeFilter = ({
  selectedDiscipline,
  setSelectedDiscipline,
  selectedExperience,
  setSelectedExperience,
  currentResults,
  ...restProps
}) => {
  const { experiences, disciplines } = useStaticQuery(graphql`
    query {
      experiences: allContentfulExperience(sort: { fields: experienceName }) {
        nodes {
          experienceName
          contentful_id
        }
      }
      disciplines: allContentfulDiscipline(sort: { fields: disciplineName }) {
        nodes {
          disciplineName
          contentful_id
        }
      }
    }
  `)

  const toggleExperience = name => {
    setSelectedExperience(selectedExperience =>
      selectedExperience.includes(name)
        ? selectedExperience.filter(e => e !== name)
        : [...selectedExperience, name]
    )
  }
  const toggleDiscipline = name => {
    setSelectedDiscipline(selectedDiscipline =>
      selectedDiscipline.includes(name)
        ? selectedDiscipline.filter(e => e !== name)
        : [...selectedDiscipline, name]
    )
  }
  const disabledDisciplines = React.useMemo(
    () =>
      disciplines.nodes.map(
        discipline =>
          currentResults &&
          !currentResults.some(
            result =>
              result.fields.discipline &&
              result.fields.discipline.sys.id === discipline.contentful_id
          )
      ),
    [disciplines, currentResults]
  )
  const disabledExperiences = React.useMemo(
    () =>
      experiences.nodes.map(
        experience =>
          currentResults &&
          !currentResults.some(
            result =>
              result.fields.experience &&
              result.fields.experience.some(
                resultExperience =>
                  resultExperience.sys &&
                  resultExperience.sys.id === experience.contentful_id
              )
          )
      ),
    [experiences, currentResults]
  )

  return (
    <div
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: ["column", "column", "row"],
      }}
      {...restProps}
    >
      <div
        sx={{
          width: ["100%", "100%", "50%"],
          mr: [0, 0, "40px"],
        }}
      >
        <h5
          sx={{
            fontSize: ["21px", "21px", "24px"],
            borderBottom: "1px solid #c0c0c0",
            pb: "5px",
            mb: "20px",
          }}
        >
          Categories
        </h5>
        <div
          sx={{
            display: "grid",
            gridAutoFlow: ["column", "column", "unset"],
            gridTemplateColumns: ["repeat(2,1fr)", "repeat(2,1fr)", "1fr"],
            gridTemplateRows: "repeat(5, 1fr)",
            justifyItems: "flex-start",
          }}
        >
          {disciplines.nodes.map((discipline, index) => (
            <FilterOptionButton
              key={index}
              disabled={disabledDisciplines[index]}
              onClick={() => toggleDiscipline(discipline.disciplineName)}
              active={selectedDiscipline.includes(discipline.disciplineName)}
            >
              /{discipline.disciplineName}
            </FilterOptionButton>
          ))}
        </div>
      </div>
      <div
        sx={{
          width: ["100%", "100%", "50%"],
          my: ["10px", "10px", "10px", 0],
        }}
      >
        <div>
          <h5
            sx={{
              fontSize: ["21px", "21px", "24px"],
              borderBottom: "1px solid #c0c0c0",
              pb: "5px",
              mb: "20px",
            }}
          >
            Experiences
          </h5>
          <div
            sx={{
              display: "grid",
              gridAutoFlow: ["column", "column", "unset"],
              gridTemplateColumns: ["repeat(2,1fr)", "repeat(2,1fr)", "1fr"],
              gridTemplateRows: "repeat(5, 1fr)",
              justifyItems: "flex-start",
              ml: "10px",
            }}
          >
            {experiences.nodes.map((experience, index) => (
              <FilterOptionButton
                key={index}
                disabled={disabledExperiences[index]}
                onClick={() => toggleExperience(experience.experienceName)}
                active={selectedExperience.includes(experience.experienceName)}
              >
                {experience.experienceName}
              </FilterOptionButton>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventTypeFilter
