/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Form from "../../form"
import { useVenueService } from "../../../services/venue-service"
import { getTimeOptions } from "../../../helpers"
const EventFormVenue = ({ onSubmit, ...restProps }) => {
  const {
    findVenues,
    checkNameIsUnique,
    response: { data: venues },
  } = useVenueService()
  const {
    getFields,
    response: { data: fields },
  } = useVenueService()
  React.useEffect(() => {
    if (!fields) getFields()
  }, [fields, getFields])
  const neighborhoods = React.useMemo(
    () =>
      fields &&
      fields
        .find(field => field.id === "neighborhood")
        .validations.find(validation => !!validation.in).in,
    [fields]
  )
  const timeOptions = React.useMemo(() => {
    const options = getTimeOptions(30)
    return [...options.slice(10, options.length), ...options.slice(0, 10)]
  }, [])
  const sections = [
    {
      marginBottom: false,
      fields: [
        {
          name: "venue",
          label: "Venue Name*",
          additionalLabel: 'Please enter "Virtual" if your event is online',
          fullWidth: true,
          required: true,
          type: "autosuggest",
          placeholder: "Enter Name",
          addText: "+ Add New Venue",
          fetch: ({ value }) => {
            if (value.length > 1) findVenues(value)
          },
          suggestions: venues || [],
          clear: () => findVenues(null),
          getValue: venue => venue.name,
          render: venue => (
            <React.Fragment>
              {venue.name}{" "}
              <span
                sx={{ fontSize: "13px", color: "swatches.secondary.darkTan" }}
              >
                {venue.neighborhood},{venue.city}
              </span>
            </React.Fragment>
          ),
          validations: [
            {
              name: "required",
              fn: () => data => {
                return !!data
              },
            },
            {
              name: "uniqueVenue",
              fn: watchData => async data => {
                if (watchData["venue-isNew"]) {
                  return await checkNameIsUnique(data)
                }
                return true
              },
              error: "Venue name already exists",
            },
          ],
        },
      ],
    },
    {
      sx: {
        display: "flex",
        flexDirection: ["column-reverse", "row"],
        justifyContent: "space-between",
        width: "100%",
      },
      fields: [
        {
          label: "New Venue Information",
          type: "group",
          containerStyle: {
            width: ["100%", "384px"],
          },
          hiddenBy: watchData => !watchData["venue-isNew"],
          fields: [
            {
              name: "newVenue.address",
              label: "Address*",
              required: true,
              type: "input",
            },

            {
              name: "newVenue.city",
              label: "City*",
              type: "input",
              required: true,
              sx: {
                display: "inline-block",
                width: "50%",
                pr: "10px",
              },
            },
            {
              name: "newVenue.zip",
              label: "Zip*",
              type: "input",
              required: true,
              sx: {
                display: "inline-block",
                width: "50%",
                pl: "10px",
              },
            },
            {
              name: "newVenue.neighborhood",
              label: "Neighborhood*",
              required: true,
              type: "select",
              options: neighborhoods,
              sx: {
                select: {
                  appearance: "none",
                  backgroundColor: "white",
                  "&::-ms-expand": {
                    display: "none",
                  },
                },
              },
            },
            { name: "newVenue.website", label: "Venue URL", type: "input" },
            {
              name: "newVenue.from",
              label: "From",
              type: "select",
              options: timeOptions,
              sx: {
                display: "inline-block",
                width: "50%",
                pr: "10px",
                select: {
                  backgroundColor: "white",
                  appearance: "none",
                  "&::-ms-expand": {
                    display: "none",
                  },
                },
              },
            },
            {
              name: "newVenue.to",
              label: "To",
              type: "select",
              options: timeOptions,
              sx: {
                display: "inline-block",
                width: "50%",
                pl: "10px",
                select: {
                  backgroundColor: "white",
                  appearance: "none",
                  "&::-ms-expand": {
                    display: "none",
                  },
                },
              },
            },
            { name: "newVenue.phone", label: "Phone Number", type: "input" },
            {
              name: "newVenue.email",
              label: "Email Contact",
              type: "input",
              validations: [
                {
                  name: "validEmail",
                  fn: () => data =>
                    !data
                      ? true
                      : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          data
                        ),
                  error: "Invalid Email",
                },
              ],
            },
          ],
        },
        {
          type: "content",
          hiddenBy: watchData => !watchData["venue-isNew"],
          content: (
            <div
              sx={{
                fontSize: "12px",
                pt: "25px",
                lineHeight: "140%",
                width: ["100%", "180px"],
              }}
            >
              <p sx={{ mb: "15px" }}>
                All new venues are subject to our approval process.
              </p>
              <p>
                We will review the new venue request along with the event
                submission and notify you within 24 hours.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      fields: [
        {
          name: "eventStartDate",
          label: "Event Start Date*",
          required: true,
          type: "date",
        },
        {
          name: "eventEndDate",
          label: "End Date",
          type: "date",
          getMinDate: watchData =>
            watchData["eventStartDate"] &&
            new Date(watchData["eventStartDate"]),
          onDataChange: (setValue, watchData) => {
            if (
              watchData["eventStartDate"] &&
              (!watchData["eventEndDate"] ||
                new Date(watchData["eventStartDate"]) >
                  new Date(watchData["eventEndDate"]))
            ) {
              setValue(watchData["eventStartDate"])
            }
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "allDayEvent",
          type: "checkbox",
          text: "All Day Event",
        },
      ],
    },
    {
      fields: [
        {
          name: "from",
          label: "From",
          type: "select",
          disabledBy: watchData => !!watchData["allDayEvent"],
          options: timeOptions,
          sx: {
            select: {
              appearance: "none",
              backgroundColor: "white",
              "&::-ms-expand": {
                display: "none",
              },
            },
          },
        },
        {
          name: "to",
          label: "To",
          type: "select",
          disabledBy: watchData => !!watchData["allDayEvent"],
          options: timeOptions,
          sx: {
            select: {
              appearance: "none",
              backgroundColor: "white",
              "&::-ms-expand": {
                display: "none",
              },
            },
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "timeOrDateExceptions",
          label: "Time or Date Exceptions",
          type: "textarea",
          height: "100px",
        },
      ],
    },
  ]
  return (
    <div {...restProps}>
      <React.Fragment>
        <Form
          sections={sections}
          submitting={false}
          onSubmit={onSubmit}
          type="secondary"
          submitText="Continue"
        />
      </React.Fragment>
    </div>
  )
}

export default EventFormVenue
