/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "./container"
import Arrow from "../images/hero-arrow.svg"
import Link from "./link"
import { toSlug } from "../helpers"
import { useWindowSize } from "react-use"
import { useIsClient } from "../hooks/use-is-client"

function FeaturedStoryHero() {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        featuredStoriesSequence {
          orderItems {
            contentful_id
            title
            images {
              file {
                url
              }
            }
            author {
              name
            }
          }
        }
      }
    }
  `)

  const featuredStory =
    data.contentfulSite.featuredStoriesSequence.orderItems[0]

  const titleRef = React.useRef(null)
  const screenSize = useWindowSize()
  const isClient = useIsClient()
  const [currentWidth, setCurrentWidth] = React.useState(null)
  React.useEffect(() => {
    isClient &&
      titleRef?.current &&
      setCurrentWidth(titleRef.current.clientWidth)
  }, [isClient, titleRef, screenSize.width])
  const maxLength = featuredStory.title
    .split(" ")
    .reduce((max, curr) => (curr.length > max.length ? curr : max)).length
  const dynamicSize = (currentWidth / maxLength) * 1.6
  return (
    <div>
      <div
        sx={{
          height: ["80vh", "90vh", "91vh"],
          m: 0,
          width: "100%",
          backgroundColor: "lightgray",
          backgroundImage: featuredStory.images
            ? `url(${featuredStory.images[0].file.url})`
            : "unset",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Container sx={{ py: 0 }}>
          <Link
            link={`/story/${toSlug(featuredStory.title)}-${
              featuredStory.contentful_id
            }`}
          >
            <div
              sx={{
                backgroundColor: "rgba(204,92,69, 0.9)",
                p: ["20px", "20px", "72px"],
              }}
            >
              <h1
                ref={titleRef}
                sx={{
                  width: "80%",
                  color: "white",
                  lineHeight: "110%",
                  fontSize: [36, 36, 36, 72].map(size =>
                    size < dynamicSize ? size : dynamicSize
                  ),
                }}
              >
                {featuredStory.title}
              </h1>
              {featuredStory.author && (
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p sx={{ color: "white", m: 0 }}>
                    By {featuredStory.author.name}
                  </p>
                  <img
                    alt="Arrow"
                    src={Arrow}
                    sx={{ width: ["60px", "60px", "60px", "100px"] }}
                  />
                </div>
              )}
            </div>
          </Link>
        </Container>
      </div>
    </div>
  )
}

export default FeaturedStoryHero
