/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import OrganizationForm from "../organization-form"

const OrganizationFormPattern = ({ blade, ...restProps }) => {
  return (
    <div
      sx={{
        backgroundColor: "white",
      }}
    >
      <Container>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OrganizationForm sx={{ width: "100%", maxWidth: "600px" }} />
        </div>
      </Container>
    </div>
  )
}

export default OrganizationFormPattern
