import React from "react"
import PropTypes from "prop-types"

export const TabsContext = React.createContext({})

const TabsController = ({ children, totalTabs }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const state = React.useMemo(() => {
    return {
      activeIndex,
      setActiveIndex,
      totalTabs,
    }
  }, [activeIndex, totalTabs])

  return <TabsContext.Provider value={state}>{children}</TabsContext.Provider>
}

TabsController.propTypes = {
  children: PropTypes.node.isRequired,
  totalSlides: PropTypes.number.isRequired,
}

export default TabsController
