/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import CTA from "../../cta"
import { useVenueService } from "../../../services/venue-service"
import { formatCardDate } from "../../../helpers"
import calendarIcon from "../../../images/calendar-primary.svg"
import clockIcon from "../../../images/hours-clock.svg"

const EventPreviewVenue = ({ data, onMakeChanges, ...restProps }) => {
  const {
    findVenues,
    response: { data: venues },
  } = useVenueService()
  const venue = data?.["venue-isNew"]
    ? { ...data.newVenue, name: data.venue }
    : venues && venues[0]
  React.useEffect(() => {
    if (!data["venue-isNew"]) findVenues(data.venue)
  }, [data, findVenues])
  return (
    <div {...restProps}>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div sx={{ fontWeight: 700, width: ["60%", "100%"] }}>
          Venue, Date, & Time
        </div>
        <CTA
          sx={{ minWidth: ["100px", "100px", "200px"] }}
          onClick={onMakeChanges}
        >
          Make Changes
        </CTA>
      </div>
      <div
        sx={{ display: "flex", flexWrap: "wrap", mt: "50px", fontSize: "13px" }}
      >
        <div
          sx={{
            maxWidth: ["100%", "50%"],
            flexBasis: ["100%", "50%"],
            pr: [0, "15px"],
            mb: ["20px", 0],
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              lineHeight: "18px",
            }}
          >
            <img
              alt=""
              sx={{ width: "16px", mt: "-2px", mr: "10px" }}
              src={calendarIcon}
            />
            {formatCardDate(data.eventStartDate, data.eventEndDate)}
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              mt: "5px",
              lineHeight: "18px",
            }}
          >
            <img
              alt=""
              sx={{ width: "16px", mt: "0px", mr: "10px" }}
              src={clockIcon}
            />
            <div>
              <div>{data.allDayEvent && "All day"}</div>
              {!data.allDayEvent && (
                <div>
                  {data.from}
                  {data.to ? " - " + data.to : null}
                </div>
              )}
              <div>{data.timeOrDateExceptions}</div>
            </div>
          </div>
        </div>
        <div
          sx={{
            maxWidth: ["100%", "50%"],
            flexBasis: ["100%", "50%"],
            pl: [0, "15px"],
            lineHeight: "130%",
          }}
        >
          {venue && (
            <React.Fragment>
              <div sx={{ color: "primary", fontWeight: 600 }}>{venue.name}</div>
              <div>{venue.address}</div>
              <div>
                {venue.city}, {venue.state} {venue.zip}
              </div>
              <div sx={{ mt: "10px" }}>{venue.phone}</div>
              <div sx={{ color: "primary" }}>{venue.website}</div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventPreviewVenue
