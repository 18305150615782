/** @jsx jsx */
import { jsx } from "theme-ui"
import { toSlug, formatDateForStories } from "../helpers"
import Link from "./link"

const MoreStoryCard = ({ story, type, ...restProps }) => {
  return (
    <Link
      link={`/story/${toSlug(story.title)}-${story.contentful_id}`}
      sx={{
        color: "#2A2D2E",
        ":hover": {
          color: "#2A2D2E",
        },
      }}
      {...restProps}
    >
      <div
        title={story.title}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: ["100%", "100%", "95%"],
          height: ["100%", "100%", "170px"],
          borderTop: "1px solid #c0c0c0",
          pt: "25px",
          mb: ["20px"],
        }}
      >
        <h4
          sx={{
            fontSize: "24px",
            mb: ["10px", "10px", "20px"],
            lineHeight: "30px",
            wordBreak: "break-word",
          }}
        >
          {story.title?.length > 60
            ? `${story.title.substring(0, 59).trim()}...`
            : story.title}
        </h4>
        {story.date && (
          <div sx={{ fontSize: "13px" }}>
            {formatDateForStories(story.date)}
          </div>
        )}
      </div>
    </Link>
  )
}

export default MoreStoryCard
