/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "../container"
import { useHistory } from "../../hooks/use-history"
import { useIsClient } from "../../hooks/use-is-client"
import { graphql, useStaticQuery } from "gatsby"
import RecentlyViewedEvents from "./recently-viewed-events"
import RecentlyViewedStories from "./recently-viewed-stories"
import NotFound from "../not-found"

const RecentlyViewed = ({ blade }) => {
  const storyHistory = useHistory("story-history")
  const eventHistory = useHistory("event-history")
  const data = useStaticQuery(graphql`
    query {
      allContentfulCollection {
        nodes {
          contentful_id
          name
          description {
            description
          }
          image {
            file {
              url
            }
          }
          discipline {
            disciplineName
            image {
              file {
                url
              }
            }
          }
          website {
            website
          }
          startDate
          endDate
          organization {
            name
            email
            organizationNumber
            website
            additionalContactInfo {
              additionalContactInfo
            }
            phone
            address
            city
            state
            zip
            logo {
              file {
                url
              }
            }
            description {
              description
            }
            facebook
            instagram
            twitter
            youtube
            approved
            secretSlug
          }
        }
      }
      allContentfulEvent {
        nodes {
          contentful_id
          eventName
          eventImage {
            file {
              url
            }
          }
          organization {
            name
            email
            organizationNumber
            website
            phone
            address
            city
            state
            zip
            description {
              description
            }
            logo {
              file {
                url
              }
            }
            facebook
            twitter
            youtube
            instagram
            approved
            secretSlug
          }
          discipline {
            disciplineName
            image {
              file {
                url
              }
            }
          }
          experience {
            experienceName
            experienceIcon {
              file {
                url
              }
            }
          }
          venue {
            name
            address
            city
            state
            zip
            phone
            location {
              lon
              lat
            }
            neighborhood
            from
            to
            website {
              website
            }
            approved
          }
          eventStartDate
          eventEndDate
          from
          to
          allDayEvent
          timeOrDateExceptions
          ticketUrl {
            ticketUrl
          }
          eventOrVenueUrl {
            eventOrVenueUrl
          }
          eventPhoneNumber
          pricingType
          minPrice
          maxPrice
          submittedBy
          eventDescription {
            eventDescription
          }
          discountAvailable
          curatorInsight {
            curatorInsight
          }
          curator {
            name
            position
            description {
              description
            }
            image {
              file {
                url
              }
            }
          }
          collection {
            contentful_id
            name
            description {
              description
            }
            image {
              file {
                url
              }
            }
            discipline {
              disciplineName
              image {
                file {
                  url
                }
              }
            }
            website {
              website
            }
            startDate
            endDate
            organization {
              name
              email
              organizationNumber
              website
              additionalContactInfo {
                additionalContactInfo
              }
              phone
              address
              city
              state
              zip
              logo {
                file {
                  url
                }
              }
              description {
                description
              }
              facebook
              instagram
              twitter
              youtube
              approved
              secretSlug
            }
          }
        }
      }
      allContentfulEditorialStory {
        nodes {
          title
          contentful_id
          teaser {
            teaser
          }
          author {
            image {
              file {
                url
              }
            }
            name
            description {
              description
            }
          }
          body {
            json
          }
          images {
            file {
              url
            }
            description
          }
          events {
            eventStartDate
            eventEndDate
            eventImage {
              file {
                url
              }
            }
            eventName
            organization {
              name
            }
            contentful_id
            discipline {
              disciplineName
            }
          }
        }
      }
    }
  `)
  const recentlyViewedEvents = React.useMemo(
    () =>
      eventHistory
        .map(id =>
          id[0] === "e"
            ? data.allContentfulEvent.nodes.find(
                event => event.contentful_id === id.split("/")[1]
              )
            : data.allContentfulCollection.nodes.find(
                collection => collection.contentful_id === id.split("/")[1]
              )
        )
        .map((element, index) =>
          element
            ? eventHistory[index][0] === "e"
              ? { ...element, type: "event" }
              : { ...element, type: "collection" }
            : null
        )
        .filter(element => {
          if (element === null) return false
          if (element.type === "event") {
            const ptEndDate = new Date(
              element.eventEndDate
            ).toLocaleString("en-US", { timeZone: "UTC" })
            const endDate = new Date(ptEndDate)
            endDate.setHours(0, 0, 0, 0)
            const ptNow = new Date().toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            })
            const now = new Date(ptNow)
            now.setHours(0, 0, 0, 0)
            console.log({ endDate, now })
            return endDate >= now
          } else {
            const ptEndDate = new Date(element.endDate).toLocaleString(
              "en-US",
              { timeZone: "UTC" }
            )
            const endDate = new Date(ptEndDate)
            const ptNow = new Date().toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            })
            const now = new Date(ptNow)
            return endDate >= now
          }
        }),
    [eventHistory]
  )
  const recentlyViewedStories = React.useMemo(
    () =>
      storyHistory
        .map(
          storyId =>
            data.allContentfulEditorialStory.nodes.find(
              story => story.contentful_id === storyId
            ) || null
        )
        .filter(story => story !== null),
    [storyHistory]
  )
  const isClient = useIsClient()
  return isClient ? (
    <React.Fragment>
      {(recentlyViewedEvents && recentlyViewedEvents.length > 0) ||
      (recentlyViewedStories && recentlyViewedStories.length > 0) ? (
        <Container>
          {recentlyViewedEvents && recentlyViewedEvents.length > 0 && (
            <RecentlyViewedEvents events={recentlyViewedEvents} />
          )}
          {recentlyViewedEvents &&
            recentlyViewedEvents.length > 0 &&
            recentlyViewedStories &&
            recentlyViewedStories.length > 0 && (
              <div
                sx={{
                  mt: "60px",
                  mb: "20px",
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#a5a5a5",
                }}
              ></div>
            )}
          {recentlyViewedStories && recentlyViewedStories.length > 0 && (
            <RecentlyViewedStories stories={recentlyViewedStories} />
          )}
        </Container>
      ) : (
        <NotFound
          topLine="/RECENTLY VIEWED"
          middleLine="You haven’t viewed any events recently"
          bottomLine="Visit a page below to start browsing:"
        />
      )}
    </React.Fragment>
  ) : null
}

export default RecentlyViewed
