/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FilterOptionButton from "./filter-option-button"
import DateRangePicker from "../../date-range-picker"
import { dateRangeFilter, dateEndRangeFilter } from "../../../helpers"

const DateRangeFilter = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  currentResults,
  ...restProps
}) => {
  return (
    <div {...restProps}>
      <div
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: ["20px", "30px", "40px", "64px"],
        }}
      >
        <div
          sx={{
            height: "47px",
            borderBottom: "1px solid #C0C0C0",
          }}
        >
          <h6 sx={{ fontSize: "10px", mb: 0, color: "#4A4A4A" }}>From</h6>
          <p sx={{ fontSize: "18px" }}>{dateRangeFilter(selectedStartDate)}</p>
        </div>
        <div
          sx={{
            height: "47px",
            borderBottom: "1px solid #C0C0C0",
          }}
        >
          <h6 sx={{ fontSize: "10px", mb: 0, color: "#4A4A4A" }}>To</h6>
          <p
            sx={{
              fontSize: "18px",
              color: !selectedEndDate ? "grey" : "black",
            }}
          >
            {dateEndRangeFilter(selectedStartDate, selectedEndDate)}
          </p>
        </div>
      </div>
      <DateRangePicker
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        onChange={dates => {
          const [start, end] = dates
          setSelectedStartDate(start)
          setSelectedEndDate(end)
        }}
      />
    </div>
  )
}

export default DateRangeFilter
