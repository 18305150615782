/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import CTA from "../../cta"
import { useCollectionService } from "../../../services/collection-service"
import { useEventService } from "../../../services/event-service"
import { formatDate } from "../../../helpers"
const EventPreviewAdditional = ({
  data,
  organization,
  onMakeChanges,
  ...restProps
}) => {
  const [imgUrl, setImgUrl] = React.useState(null)
  React.useEffect(() => {
    if (
      data["collection-isNew"] &&
      data.collectionImage &&
      data.collectionImage[0]
    ) {
      const reader = new FileReader()
      reader.readAsDataURL(data.collectionImage[0])
      reader.onload = () => {
        setImgUrl(reader.result)
      }
    }
  }, [data])
  const {
    findCollectionsByPartialName,
    response: { data: collections },
  } = useCollectionService()
  const {
    findEventsByCollection,
    response: { data: events },
  } = useEventService()
  const collection = React.useMemo(
    () =>
      data?.["collection-isNew"]
        ? { ...data.newCollection, name: data.collection, imgUrl }
        : collections &&
          collections[0] && {
            ...collections[0],
            startDate: formatDate(collections[0].startDate),
            endDate:
              collections[0].endDate && formatDate(collections[0].endDate),
            discipline: collections[0].discipline.fields.disciplineName,
            imgUrl: collections[0].image.fields.file.url + "?w=150",
          },
    [data, collections, imgUrl]
  )
  React.useEffect(() => {
    if (!data["collection-isNew"])
      findCollectionsByPartialName(data.collection, organization)
  }, [data, organization, findCollectionsByPartialName])
  React.useEffect(() => {
    if (!data["collection-isNew"])
      findEventsByCollection(collection, organization)
  }, [data, collection, organization, findEventsByCollection])
  return (
    <div {...restProps}>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div sx={{ fontWeight: 700, width: "60%" }}>Additional Information</div>
        <CTA
          sx={{ minWidth: ["100px", "100px", "200px"] }}
          onClick={onMakeChanges}
        >
          Make Changes
        </CTA>
      </div>
      <div sx={{ display: "flex", flexWrap: "wrap", mt: "50px" }}>
        <div
          sx={{
            maxWidth: ["100%", "50%"],
            flexBasis: ["100%", "50%"],
            pr: [0, "15px"],
            mb: ["20px", 0],
            fontSize: "13px",
          }}
        >
          {(data.ticketUrl || data.eventOrVenueUrl) && (
            <div sx={{ mb: "20px" }}>
              <div sx={{ color: "primary", fontWeight: 600 }}>
                {data.ticketUrl.ticketUrl}
              </div>
              <div sx={{ color: "primary", fontWeight: 600 }}>
                {data.eventOrVenueUrl.website}
              </div>
            </div>
          )}
          <div sx={{ mb: "20px" }}>
            <div>Ticket Prices</div>
            <div sx={{ fontWeight: 600 }}>
              {data.pricingType === "Custom"
                ? `$${data.minPrice} - $${data.maxPrice}`
                : data.pricingType}
            </div>
          </div>
          {data.discountAvailable && data.discountAvailable.length > 0 && (
            <div>
              {data.discountAvailable
                .map(
                  (discount, index) =>
                    (index === 0 ? discount : discount.toLowerCase()) +
                    " discount"
                )
                .join(", ")}
            </div>
          )}
          {data.eventPhoneNumber && <div>{data.eventPhoneNumber}</div>}
        </div>
        {collection && data.collection && (
          <div
            sx={{
              border: "1px solid #c0c0c0",
              backgroundColor: "#f7f7f5",
              padding: "30px",
              width: "100%",
            }}
          >
            <h6 sx={{ mb: "10px" }}>Added to {collection.name}</h6>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: ["1fr", "1fr 1fr"],
              }}
            >
              <div>
                <h6 sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  <div sx={{ mb: "0px" }}>
                    /{collection.discipline.toUpperCase()}
                  </div>
                  <div sx={{ mb: "10px", fontWeight: 500 }}>
                    {collection.startDate}
                    {collection.endDate && ` - ${collection.endDate}`}
                  </div>
                </h6>
                <img alt="" sx={{ width: "150px" }} src={collection.imgUrl} />
              </div>
              {events && (
                <div>
                  <div sx={{ fontSize: "13px", color: "#a5a5a5", mb: "5px" }}>
                    Other events in the collection:
                  </div>
                  {events.map((event, index) => (
                    <div
                      key={index}
                      sx={{ fontSize: "12px", lineHeight: "16px" }}
                    >
                      <span>{event.eventName}</span>
                      <span
                        sx={{
                          color: "#a5a5a5",
                          fontStyle: "italic",
                          fontSize: "10px",
                          ml: "5px",
                        }}
                      >
                        ({event.approved ? "approved" : "pending"})
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventPreviewAdditional
