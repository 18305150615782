/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import RangeSlider from "../../range-slider"
import Input from "../../input"

const PriceRangeFilter = ({
  selectedPriceRange,
  setSelectedPriceRange,
  ...restProps
}) => {
  const [internalValues, setInternalValues] = React.useState({
    min: 0,
    max: 500,
  })
  React.useEffect(() => {
    setInternalValues(selectedPriceRange)
  }, [setInternalValues, selectedPriceRange])
  const onFocus = () => {
    setSelectedPriceRange(values => ({
      ...values,
      enabled: true,
    }))
  }
  const onMinChange = e => {
    const value = parseInt(e.target.value)
    setInternalValues(values => ({
      ...values,
      min: value,
    }))
  }
  const onMaxChange = e => {
    const value = parseInt(e.target.value)
    setInternalValues(values => ({
      ...values,
      max: parseInt(value),
    }))
  }
  const onBlur = () => {
    if (isNaN(internalValues.min)) {
      setSelectedPriceRange(values => ({ ...values, enabled: false }))
    } else {
      setSelectedPriceRange({
        ...internalValues,
        max:
          internalValues.max < internalValues.min
            ? internalValues.min
            : internalValues.max,
      })
    }
  }
  return (
    <div {...restProps}>
      <h5
        sx={{
          fontSize: "18px",
          borderBottom: "1px solid #c0c0c0",
          pb: "5px",
          mb: "20px",
        }}
      >
        Price Range
      </h5>
      <div>
        {selectedPriceRange && (
          <RangeSlider
            step={10}
            min={0}
            max={500}
            values={selectedPriceRange}
            setValues={setSelectedPriceRange}
            sx={{ mt: "30px", mb: "10px" }}
          />
        )}
        <div
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "20px",
          }}
        >
          <div>
            <div sx={{ fontSize: "13px" }}>Min Price</div>
            <div
              sx={{
                width: "20px",
                mr: "-20px",
                display: "inline-block",
                position: "relative",
                left: "14px",
                zIndex: 1,
                pointerEvents: "none",
                color: selectedPriceRange.enabled
                  ? "body"
                  : "swatches.secondary.tan",
              }}
            >
              $
            </div>
            <Input
              type="number"
              min={0}
              value={!selectedPriceRange.enabled ? "" : internalValues.min}
              onChange={onMinChange}
              onFocus={onFocus}
              onBlur={onBlur}
              sx={{
                pl: "25px",
                display: "inline-block",
                width: "100%",
              }}
            />
          </div>
          <div>
            <div sx={{ fontSize: "13px" }}>Max Price</div>
            <div
              sx={{
                width: "20px",
                mr: "-20px",
                display: "inline-block",
                position: "relative",
                left: "14px",
                zIndex: 1,
                pointerEvents: "none",
                color: selectedPriceRange.enabled
                  ? "body"
                  : "swatches.secondary.tan",
              }}
            >
              $
            </div>
            <Input
              type="number"
              value={!selectedPriceRange.enabled ? "" : internalValues.max}
              onChange={onMaxChange}
              onFocus={onFocus}
              onBlur={onBlur}
              sx={{
                pl: "25px",
                display: "inline-block",
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PriceRangeFilter
