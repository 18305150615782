import React from "react"
import { useContentfulClient } from "../hooks/use-contentful-client"

export const useDisciplineService = () => {
  const { getClient } = useContentfulClient()
  const [response, setResponse] = React.useState({
    data: null,
    loading: false,
    error: null,
  })
  const findDisciplines = React.useCallback(
    async additionalQuery => {
      setResponse(response => ({ ...response, loading: true }))
      try {
        const entries = await getClient().getEntries({
          include: 3,
          content_type: "discipline",
          ...additionalQuery,
        })
        setResponse({
          data: entries.items.map(item => item.fields),
          loading: false,
        })
      } catch (err) {
        setResponse(response => ({ ...response, loading: false, error: err }))
      }
    },
    [setResponse, getClient]
  )

  return {
    findDisciplines,
    response,
  }
}
