/** @jsx jsx */
import { jsx } from "theme-ui"
import { toSlug } from "../helpers"
import Link from "./link"

const StoryCard = ({ story, type, ...restProps }) => {
  return (
    <Link
      link={`/story/${toSlug(story.title)}-${story.contentful_id}`}
      sx={{
        backgroundColor: "#ccc8c0",
        color: "#2A2D2E",
        ":hover": {
          color: "#2A2D2E",
        },
      }}
      {...restProps}
    >
      <div
        title={story.title}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          sx={{
            height: ["345px", "345px", "410px"],
            backgroundImage: story.images
              ? `url(${story.images[0].file.url})`
              : "unset",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <div
          sx={{
            height: ["195px", "195px", "230px"],
            p: "18px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            sx={{
              fontSize: "13px",
              letterSpacing: "1.5px",
              fontWeight: "500",
              mb: "9px",
            }}
          >
            /STORIES
          </div>
          <h4
            sx={{
              flexGrow: 1,
              mb: "18px",
              fontSize: ["24px", "24px", "28px", "28px", "32px"],
              wordBreak: "break-word",
            }}
          >
            {story.title.length > 50
              ? `${story.title.substring(0, 49).trim()}...`
              : story.title}
          </h4>
          {story.author && (
            <div sx={{ fontSize: "13px" }}>By {story.author.name}</div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default StoryCard
