/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "../container"
import { useWindowSize } from "react-use"
import Link from "../link"

const Item = ({
  card,
  triangleColor,
  leftTriangle,
  rightTriangle,
  ...restProps
}) => (
  <Link link={card.url || "/"}>
    <div
      sx={{
        backgroundImage: card && [
          `url(${card.mobileImage?.file?.url}?w=512&h=512)`,
          `url(${card.mobileImage?.file?.url}?w=512&h=512)`,
          `url(${card.desktopImage?.file?.url}?w=512&h=512)`,
        ],
        backgroundPosition: "center",
        backgroundSize: "cover",
        border: theme => `1px solid ${theme.colors.backgrounds.secondary}`,
        boxSizing: "content-box",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        alignItems: "center",
        ":hover .triangle": {
          "&.left": {
            left: "-10px",
          },
          "&.right": {
            left: "10px",
          },
          opacity: 0,
        },
      }}
      {...restProps}
    >
      {card.title && (
        <h5
          sx={{
            position: "relative",
            zIndex: 1,
            fontWeight: 500,
            color: "white",
            mb: 0,
            textAlign: "center",
          }}
        >
          /{card.title.toUpperCase()}
        </h5>
      )}
      <div
        sx={{
          background: "rgba(42,45,46,0.3)",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
        }}
      ></div>
      {leftTriangle && (
        <div
          className={"triangle left"}
          sx={{
            opacity: 0.92,
            transition: "0.5s",
            backgroundColor: triangleColor || "red",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            clipPath: `polygon(0% 0%, ${leftTriangle[0]}% 0%, ${leftTriangle[1]}% 100%, 0% 100%)`,
          }}
        ></div>
      )}
      {rightTriangle && (
        <div
          className={"triangle right"}
          sx={{
            opacity: 0.92,
            transition: "0.5s",
            backgroundColor: triangleColor || "red",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            clipPath: `polygon(105% 0%, ${rightTriangle[0]}% 0%, ${rightTriangle[1]}% 100%, 105% 100%)`,
          }}
        ></div>
      )}
    </div>
  </Link>
)
const CategoriesPattern = ({ blade }) => {
  const [containerWidth, setContainerWidth] = React.useState(0)
  const containerRef = React.useRef(null)
  const { width: windowWidth } = useWindowSize(0)
  React.useEffect(() => {
    containerRef.current && setContainerWidth(containerRef.current.clientWidth)
  }, [windowWidth, containerRef])
  const fullWidth = [
    containerWidth / 2 - 4 + "px",
    containerWidth / 2 - 4 + "px",
    containerWidth / 3 - 4 + "px",
  ]
  const halfHeight = [
    containerWidth / 4 - 4 + "px",
    containerWidth / 4 - 4 + "px",
    containerWidth / 6 - 4 + "px",
  ]
  return (
    <div
      sx={{
        backgroundColor: "backgrounds.secondary",
        py: [0, 0, "50px"],
      }}
    >
      <Container
        sx={{
          maxWidth: ["1000px", "1000px", "1000px"],
        }}
      >
        <h5 sx={{ fontSize: "30px" }}>{blade.title}</h5>
        <div ref={containerRef} sx={{ width: "100%" }}>
          <div
            sx={{
              display: containerWidth ? "flex" : "none",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div>
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[40, 0]}
                rightTriangle={[100, 60]}
                triangleColor={"primary"}
                card={blade.contentCards[0]}
              />
              <Item
                sx={{ width: fullWidth, height: halfHeight }}
                leftTriangle={[60, 40]}
                triangleColor={"swatches.primary.teal"}
                card={blade.contentCards[1]}
              />
            </div>
            <div>
              <Item
                sx={{ width: fullWidth, height: halfHeight }}
                rightTriangle={[60, 40]}
                triangleColor={"swatches.primary.teal"}
                card={blade.contentCards[2]}
              />
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[40, 0]}
                rightTriangle={[100, 60]}
                triangleColor={"primary"}
                card={blade.contentCards[3]}
              />
            </div>
            <div>
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[20, -20]}
                rightTriangle={[80, 40]}
                triangleColor={"swatches.secondary.tan"}
                card={blade.contentCards[4]}
              />
              <Item
                sx={{ width: fullWidth, height: halfHeight }}
                leftTriangle={[40, 20]}
                rightTriangle={[100, 80]}
                triangleColor={"primary"}
                card={blade.contentCards[5]}
              />
            </div>
            {/* Desktop */}
            <div sx={{ display: ["none", "none", "block"] }}>
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[40, 0]}
                rightTriangle={[100, 60]}
                triangleColor={"swatches.secondary.tan"}
                card={blade.contentCards[6]}
              />
            </div>
            <div sx={{ display: ["none", "none", "block"] }}>
              <Item
                sx={{ width: fullWidth, height: halfHeight }}
                leftTriangle={[60, 40]}
                triangleColor={"primary"}
                card={blade.contentCards[7]}
              />
              <Item
                sx={{
                  width: fullWidth,
                  height: halfHeight,
                  pt: "2px",
                }}
                leftTriangle={[40, 20]}
                rightTriangle={[100, 80]}
                triangleColor={"swatches.primary.teal"}
                card={blade.contentCards[8]}
              />
            </div>
            <div sx={{ display: ["none", "none", "block"] }}>
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[20, -20]}
                rightTriangle={[80, 40]}
                triangleColor={"swatches.secondary.darkTan"}
                card={blade.contentCards[9]}
              />
            </div>
            {/* Mobile */}
            <div sx={{ display: ["block", "block", "none"] }}>
              <Item
                sx={{ width: fullWidth, height: halfHeight }}
                leftTriangle={[40, 20]}
                rightTriangle={[100, 80]}
                triangleColor={"swatches.primary.teal"}
                card={blade.contentCards[6]}
              />
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[20, -20]}
                rightTriangle={[80, 40]}
                triangleColor={"swatches.secondary.tan"}
                card={blade.contentCards[7]}
              />
            </div>
            <div sx={{ display: ["block", "block", "none"] }}>
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[20, -20]}
                rightTriangle={[80, 40]}
                triangleColor={"swatches.primary.teal"}
                card={blade.contentCards[8]}
              />
            </div>
            <div sx={{ display: ["block", "block", "none"] }}>
              <Item
                sx={{ width: fullWidth, height: fullWidth }}
                leftTriangle={[40, 0]}
                rightTriangle={[100, 60]}
                triangleColor={"swatches.secondary.darkTan"}
                card={blade.contentCards[9]}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default CategoriesPattern
