/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Filters from "./filters"
import Calendar from "./calendar"
import Results from "./results"
import { useEventService } from "../../services/event-service"
import { getEventCalendarDates } from "../../helpers"
import SpinnerSection from "../spinner-section"
import Container from "../container"

const EventsCalendar = ({
  location: { search },
  desktopAdUrl,
  mobileAdUrl,
  ...restProps
}) => {
  const { findEvents, response } = useEventService()
  const [filter, setFilter] = React.useState(null)
  React.useEffect(() => {
    findEvents({
      "fields.eventEndDate[gte]": getEventCalendarDates().today,
    })
  }, [findEvents])
  const filteredResults = React.useMemo(
    () =>
      response.data && (filter ? response.data.filter(filter) : response.data),
    [response, filter]
  )
  return (
    <React.Fragment>
      <Filters
        search={search}
        filter={filter}
        setFilter={setFilter}
        currentResults={filteredResults}
        {...{ desktopAdUrl, mobileAdUrl }}
      />
      <div sx={{ backgroundColor: "backgrounds.primary" }}>
        <Container>
          {filter ? (
            !response.data || response.loading ? (
              <SpinnerSection />
            ) : filteredResults.length === 0 ? (
              <h5 sx={{ my: "80px" }}>
                Sorry, we couldn’t find anything matching your search.
              </h5>
            ) : (
              <Results
                results={filteredResults}
                {...{ desktopAdUrl, mobileAdUrl }}
              />
            )
          ) : (
            <Calendar {...{ desktopAdUrl, mobileAdUrl }} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EventsCalendar
