/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import { TabsContext } from "./context"
import { AnimatePresence, motion } from "framer-motion"

const TabPanel = ({ content, animation, animationDuration, ...restProps }) => {
  const { activeIndex } = React.useContext(TabsContext)
  return (
    <div {...restProps}>
      <AnimatePresence initial={false} exitBeforeEnter={true}>
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          key={activeIndex}
        >
          {content[activeIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
TabPanel.propTypes = {
  content: PropTypes.arrayOf(PropTypes.node),
}
export default TabPanel
