/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./context"

const CarouselSlider = ({ children }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)
  return (
    <SwipeableViews
      enableMouseEvents
      index={activeIndex}
      onChangeIndex={setActiveIndex}
    >
      {children}
    </SwipeableViews>
  )
}

export default CarouselSlider
