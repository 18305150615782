/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import starLg from "../../images/about-star-lg.svg"
import starSm from "../../images/about-star-sm.svg"
import { hasClass } from "../../helpers"

const GreaterAboutPattern = ({ blade }) => {
  return (
    <Container
      showSeparator={true}
      outerStyle={{ backgroundColor: "backgrounds.primary" }}
      sx={{
        marginTop: [0, 0, "20px"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          sx={{
            width: "100%",
            maxWidth: "585px",
            textAlign: "center",
            alignSelf: "center",
            marginBottom: "45px",
          }}
        >
          <h5
            sx={{
              fontSize: ["18px", "18px", "24px"],
            }}
          >
            {blade.title}
          </h5>
        </div>
        <div sx={{ mx: "20px" }}>
          <div
            sx={{
              display: ["none", "none", "block"],
              borderTop: "2px solid #D5D3D3",
              width: "100%",
              position: "relative",
              top: "51.5px",
            }}
          ></div>
        </div>
        <div
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: ["column", "column", "row"],
            width: [null, null, "100%"],
            margin: "0 auto",
            justifyContent: "space-between",
          }}
        >
          {blade.contentCards &&
            blade.contentCards.map((card, index) => (
              <div
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "start",
                  width: [null, null, `${100 / blade.contentCards.length}%`],
                  maxWidth: "210px",
                  px: "10px",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: ["10px", "10px", 0],
                  }}
                >
                  <div
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                      my: "10px",
                    }}
                  >
                    <div
                      sx={{
                        height: "100%",
                        backgroundColor:
                          index === 0 ? "backgrounds.primary" : "transparent",
                        flexGrow: 1,
                      }}
                    ></div>
                    <img
                      alt=""
                      src={hasClass(card, "star-lg") ? starLg : starSm}
                      sx={{
                        backgroundColor: "backgrounds.primary",
                        mx: "auto",
                        width: hasClass(card, "star-lg") ? "65px" : "48px",
                        padding: "10px",
                      }}
                    />
                    <div
                      sx={{
                        height: "100%",
                        backgroundColor:
                          index === blade.contentCards.length - 1
                            ? "backgrounds.primary"
                            : "transparent",
                        flexGrow: 1,
                      }}
                    ></div>
                  </div>
                  <p
                    sx={{
                      fontWeight: "400",
                      textAlign: "center",
                      fontSize: ["15px", "15px", "18px"],
                    }}
                  >
                    {card.title}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  )
}

export default GreaterAboutPattern
