/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Form from "../../form"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../../link"

const validateSize = ({ minW, minH, maxW, maxH }) => async data =>
  await new Promise(resolve => {
    const urlCreator = window.URL || window.webkitURL
    const imageUrl = urlCreator.createObjectURL(data[0])
    const image = new Image()
    image.src = imageUrl
    image.onload = () => {
      resolve(
        (minW ? image.width >= minW : true) &&
          (minH ? image.height >= minH : true) &&
          (maxW ? image.width <= maxW : true) &&
          (maxH ? image.height <= maxH : true)
      )
    }
  })

const EventFormDetails = ({ onSubmit, ...restProps }) => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulDiscipline {
        nodes {
          disciplineName
        }
      }
      allContentfulExperience {
        nodes {
          experienceName
        }
      }
    }
  `)
  const disciplines = query.allContentfulDiscipline.nodes.map(
    node => node.disciplineName
  )
  const experiences = query.allContentfulExperience.nodes.map(
    node => node.experienceName
  )
  const sections = [
    {
      marginBottom: false,
      fields: [
        {
          name: "eventName",
          label: "Event Name*",
          required: true,
          type: "input",
        },
        {
          name: "eventDescription",
          label: "Event Description*",
          maxLength: 450,
          required: true,
          type: "textarea",
          height: "103px",
        },
        {
          name: "eventImage",
          label: "Upload Event Image*",
          type: "image",
          required: true,
          validations: [
            {
              name: "minSize",
              fn: () => validateSize({ minW: 800, minH: 450 }),
              error: (
                <div sx={{ lineHeight: "16px" }}>
                  <span>
                    Your image is too small and does not match the required
                    format listed.{" "}
                  </span>
                  <Link
                    forceExternal={true}
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                    link={"/upload-guidelines"}
                  >
                    View upload guidelines.
                  </Link>
                </div>
              ),
            },
            {
              name: "maxSize",
              fn: () => validateSize({ maxW: 1920, maxH: 1080 }),
              error: (
                <div sx={{ lineHeight: "16px" }}>
                  <span>
                    Your image is too large and does not match the required
                    format listed.{" "}
                  </span>
                  <Link
                    forceExternal={true}
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                    link={"/upload-guidelines"}
                  >
                    View upload guidelines.
                  </Link>
                </div>
              ),
            },
          ],
          children: (
            <React.Fragment>
              <p sx={{ mb: "15px" }}>
                Image should be{" "}
                <span sx={{ fontWeight: 600 }}>1200 x 675 pixels</span>, JPG or
                PNG saved for web, 5MB max file size. Low resolution, square, or
                images with copy will not be approved.
              </p>

              <p sx={{ mb: "30px" }}>
                Drag and drop your event image here or choose a file on your
                local drive.
              </p>
            </React.Fragment>
          ),
        },
      ],
    },
    {
      fields: [
        {
          name: "discipline",
          label: "Discipline*",
          required: true,
          type: "select",
          options: disciplines,
          sx: {
            select: {
              appearance: "none",
              backgroundColor: "white",
              "&::-ms-expand": {
                display: "none",
              },
            },
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "experience",
          label: (
            <span>
              Experience Type{" "}
              <span sx={{ fontStyle: "italic" }}>(Select all that apply)</span>
            </span>
          ),
          type: "checkbox-group",
          columns: true,
          options: experiences,
          fullWidth: true,
        },
      ],
    },
  ]
  return (
    <div {...restProps}>
      <React.Fragment>
        <Form
          sections={sections}
          submitting={false}
          onSubmit={onSubmit}
          type="secondary"
          submitText="Continue"
        />
      </React.Fragment>
    </div>
  )
}

export default EventFormDetails
