/** @jsx jsx */
import { jsx } from "theme-ui"
import Tabs from "../tabs"
import Container from "../container"
import ContentCard from "../content-card"

const TabsPattern = ({ blade }) => {
  return (
    <Container>
      <ContentCard card={{ ...blade, patternType: "Blade" }} />
      <Tabs
        data={blade.contentCards.map(card => ({
          title: card.title,
          content: <ContentCard card={card} />,
        }))}
      />
    </Container>
  )
}

export default TabsPattern
