/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useSlider } from "react-use"
import gripperIcon from "../images/filter-price-gripper.svg"
import { useMouse } from "../hooks/use-mouse"

const RangeSlider = ({
  step = 10,
  min = 0,
  max = 100,
  values,
  setValues,
  ...restProps
}) => {
  const currentMax = Math.max(max, values.max)
  const [pos, setPos] = React.useState(0)
  const [activeButton, setActiveButton] = React.useState(null)
  const containerRef = React.useRef(null)
  React.useEffect(() => {
    const value = pos * (currentMax - min) + min
    if (activeButton === "min")
      setValues(values => ({
        ...values,
        min: value <= values.max ? value : values.max,
        enabled: true,
      }))
    if (activeButton === "max")
      setValues(values => ({
        ...values,
        max: value >= values.min ? value : values.min,
        enabled: true,
      }))
  }, [activeButton, pos, setValues])
  const mouseState = useMouse(containerRef)
  React.useEffect(() => {
    let relativePos = mouseState.elX / mouseState.elW
    if (relativePos < 0) relativePos = 0
    if (relativePos > 1) relativePos = 1
    const steps = (currentMax - min) / step
    const pos = Math.round(steps * relativePos) / steps
    setPos(pos)
  }, [mouseState])
  const resetActiveButton = React.useCallback(() => {
    setActiveButton(null)
  }, [setActiveButton])
  React.useEffect(() => {
    window.addEventListener("mouseup", resetActiveButton)
    return () => window.removeEventListener("mouseup", resetActiveButton)
  }, [resetActiveButton])
  const renderHandle = name => {
    return (
      <div
        sx={{
          overflow: "visible",
          userSelect: "none",
          position: "absolute",
          left:
            (values[name] / (currentMax - min)) *
              (mouseState.elW ||
                containerRef?.current?.getBoundingClientRect().width) +
            "px",
          ml: "-12px",
          cursor: "pointer",
        }}
        onMouseDown={() => setActiveButton(name)}
        onTouchStart={() => setActiveButton(name)}
        onMouseUp={() => setActiveButton(null)}
        onTouchEnd={() => setActiveButton(null)}
      >
        <div>
          <img
            src={gripperIcon}
            sx={{
              pointerEvents: "none",
              width: "24px",
              minWidth: "24px",
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <div {...restProps}>
      <div sx={{ mx: "16px" }}>
        <div
          ref={ref => (containerRef.current = ref)}
          sx={{
            width: "100%",
            height: "24px",
            position: "relative",
            overlfow: "visible",
          }}
        >
          <div
            sx={{
              position: "absolute",
              top: "11px",
              left: "-16px",
              pointerEvents: "none",
              width: "calc(100% + 32px)",
              height: "2px",
              backgroundColor: values.enabled
                ? "#4a4a4a"
                : "swatches.secondary.tan",
            }}
          ></div>
          {renderHandle("min")}
          {renderHandle("max")}
        </div>
      </div>
    </div>
  )
}
export default RangeSlider
