/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import Blog from "../blog"
import ContentCard from "../content-card"

const BlogPattern = ({ blade, ...restProps }) => {
  return (
    <Container>
      <ContentCard card={{ ...blade, patternType: "Blade" }} />
      <Blog {...restProps} />
    </Container>
  )
}

export default BlogPattern
