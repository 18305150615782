import React from "react"

export const useMouse = ref => {
  const [state, setState] = React.useState({
    docX: 0,
    docY: 0,
    posX: 0,
    posY: 0,
    elX: 0,
    elY: 0,
    elH: 0,
    elW: 0,
  })

  const getElState = React.useCallback(() => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect(),
        left = rect.left,
        top = rect.top,
        elW = rect.width,
        elH = rect.height
      const posX = left + window.pageXOffset
      const posY = top + window.pageYOffset
      return {
        posX: posX,
        posY: posY,
        elH: elH,
        elW: elW,
      }
    }
  }, [ref])
  React.useEffect(() => {
    const moveHandler = event => {
      const elState = getElState()
      if (elState) {
        setState({
          ...elState,
          docX: event.pageX,
          docY: event.pageY,
          elX: event.pageX - elState.posX,
          elY: event.pageY - elState.posY,
        })
      }
    }
    const touchHandler = event => {
      const touchEvent = event.changedTouches[0]
      moveHandler(touchEvent)
    }
    const resizeHandler = event => {
      setState(state => ({ ...state, ...getElState() }))
    }
    document.addEventListener("mousemove", moveHandler)
    document.addEventListener("touchmove", touchHandler)
    document.addEventListener("resize", resizeHandler)
    return function() {
      document.removeEventListener("mousemove", moveHandler)
      document.removeEventListener("touchmove", touchHandler)
      document.removeEventListener("resize", resizeHandler)
    }
  }, [getElState])

  return state
}
