/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import EventCarousel from "./event-carousel"
import SpinnerSection from "./spinner-section"
import { useEventService } from "../services/event-service"
import CTA from "./cta"
import Link from "./link"
import Container from "./container"
import Ad from "./ad"

const Experiences = ({ desktopAdUrl, mobileAdUrl, ...restProps }) => {
  const {
    findEventsGroupedByExperience,
    response: { data: eventsByExperience },
  } = useEventService()
  React.useEffect(() => {
    findEventsGroupedByExperience({
      "fields.eventEndDate[gte]": new Date(),
    })
  }, [findEventsGroupedByExperience])
  const sort = (a, b) => {
    const aStartDate = new Date(a.startDate)
    const aEndDate = new Date(a.endDate)
    const bStartDate = new Date(b.startDate)
    const bEndDate = new Date(b.endDate)
    if (aStartDate - bStartDate === 0) return aEndDate - bEndDate
    return aStartDate - bStartDate
  }
  const [visibleItems, setVisibleItems] = React.useState(4)
  if (!eventsByExperience) return <SpinnerSection />
  if (eventsByExperience.every(item => item.events.length === 0)) return null
  return (
    <Container>
      <div
        sx={{
          py: [0, 0, "50px"],
        }}
      >
        <div sx={{ mb: "25px" }}>
          <h5 sx={{ fontSize: "30px", mb: "15px" }}>/Experiences</h5>
        </div>
        <div>
          {eventsByExperience
            .filter(({ experience, events }) => events.length > 0)
            .slice(0, visibleItems)
            .map(({ experience, events }, index) => (
              <React.Fragment>
                {index % 2 === 0 && index !== 0 && (
                  <Ad
                    {...{ desktopAdUrl, mobileAdUrl }}
                    sx={{
                      my: "60px",
                      mx: theme =>
                        theme.spacing.horizontal.map(spacing => -spacing),
                    }}
                  />
                )}
                <div key={index}>
                  <div
                    sx={{
                      mb: "-10px",
                    }}
                  >
                    <div
                      sx={{
                        height: "2px",
                        width: "100%",
                        backgroundColor: "#c0c0c0",
                        mb: "30px",
                      }}
                    ></div>
                    <div
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          sx={{ height: "32px" }}
                          alt={experience.experienceName}
                          src={experience.experienceIcon.file.url}
                        />
                        <h5
                          sx={{
                            fontSize: "24px",
                            mt: "5px",
                            ml: "15px",
                            mb: 0,
                          }}
                        >
                          {experience.experienceName}
                        </h5>
                      </div>
                      <Link
                        sx={{ mt: "5px" }}
                        link={
                          "/calendar?experiences=" +
                          encodeURIComponent(experience.experienceName)
                        }
                      >
                        See All
                      </Link>
                    </div>
                  </div>
                  <EventCarousel events={events} collections={[]} sort={sort} />
                </div>
              </React.Fragment>
            ))}
        </div>
        {visibleItems <
          eventsByExperience.filter(
            ({ experience, events }) => events.length > 0
          ).length && (
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CTA
              onClick={() => setVisibleItems(visibleItems => visibleItems + 4)}
            >
              More Experiences +
            </CTA>
          </div>
        )}
      </div>
    </Container>
  )
}

export default Experiences
