/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "../container"
import Accent from "../accent"
import ContactForm from "../contact-form"

const ContactFormPattern = ({ blade, ...restProps }) => {
  return (
    <div sx={{ backgroundColor: "white" }}>
      <div sx={{ height: ["100px", "150px", "250px"], overflow: "hidden" }}>
        <Accent
          colorA="swatches.secondary.lightTan"
          colorB="primary"
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 0,
            height: ["300px", "350px", "600px"],
          }}
        />
      </div>
      <Container sx={{ maxWidth: "584px" }}>
        <ContactForm sx={{ width: "100%" }} />
      </Container>
    </div>
  )
}

export default ContactFormPattern
