import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useAPI } from "../hooks/use-api"

export const useContactService = () => {
  const {
    site: {
      siteMetadata: { apiUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
  `)
  const [response, setResponse] = React.useState({
    data: null,
    loading: false,
    error: null,
  })
  const api = useAPI(setResponse)
  const post = React.useCallback(
    ({ data, callback }) => {
      api.post({
        data,
        files: [],
        callback,
        url: `${apiUrl}/contact`,
      })
    },
    [api, apiUrl]
  )
  return {
    post,
    response,
  }
}
