/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import CTA from "../../cta"
const EventPreviewDetails = ({ data, onMakeChanges, ...restProps }) => {
  const [imgUrl, setImgUrl] = React.useState(null)
  React.useEffect(() => {
    const reader = new FileReader()
    reader.readAsDataURL(data.eventImage[0])
    reader.onload = () => {
      setImgUrl(reader.result)
    }
  }, [data])

  return (
    <div {...restProps}>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div sx={{ fontWeight: 700, width: ["60%", "100%"] }}>
          Event Details
        </div>
        <CTA
          sx={{ minWidth: ["100px", "100px", "200px"] }}
          onClick={onMakeChanges}
        >
          Make Changes
        </CTA>
      </div>
      <div sx={{ display: "flex", flexWrap: "wrap", mt: "50px" }}>
        <div
          sx={{
            maxWidth: ["100%", "50%"],
            flexBasis: ["100%", "50%"],
            pr: [0, "15px"],
            mb: ["20px", 0],
          }}
        >
          <div sx={{ fontSize: "12px", letterSpacing: "1.5px", mb: "5px" }}>
            /{data.discipline.toUpperCase()}
          </div>
          <h6 sx={{ mb: "10px" }}>{data.eventName}</h6>
          {data.experience && (
            <div sx={{ fontSize: "13px", color: "primary", fontWeight: 400 }}>
              {data.experience.join(", ")}
            </div>
          )}
          <div
            sx={{
              fontSize: "13px",
              mt: "30px",
              fontWeight: 400,
              lineHeight: "130%",
            }}
          >
            {data.eventDescription}
          </div>
        </div>
        <img
          alt=""
          sx={{
            width: ["100%", "50%"],
            height: "100%",
            flexBasis: ["100%", "50%"],
            pl: [0, "15px"],
          }}
          src={imgUrl}
        />
      </div>
    </div>
  )
}

export default EventPreviewDetails
