/** @jsx jsx */
import { jsx } from "theme-ui"
import Hero from "./hero"
import Link from "./link"
import backgroundImage from "../images/404-backgrond.jpg"
const NotFound = ({ topLine, middleLine, bottomLine }) => {
  return (
    <Hero
      desktopImage={{ file: { url: backgroundImage } }}
      mobileImage={{ file: { url: backgroundImage } }}
      sx={{ minHeight: "100vh" }}
    >
      <div sx={{ minHeight: "100vh", color: "" }}>
        <div sx={{ height: "100%", mt: "60px", mb: "280px" }}>
          <h5
            sx={{
              fontSize: "17px",
              letterSpacing: "1.7px",
              fontWeight: 500,
              color: "#3E4447",
            }}
          >
            {topLine}
          </h5>
          <h2
            sx={{
              fontSize: "60px",
              lineHeight: "72px",
              maxWidth: "884px",
              color: "#2F3334",
            }}
          >
            {middleLine}
          </h2>
          <h5 sx={{ color: "#000000" }}>{bottomLine}</h5>
          <div sx={{ display: "flex", flexDirection: "row" }}>
            <Link
              sx={{ color: "primary", fontWeight: 500, mr: "40px" }}
              link="/"
            >
              /Home
            </Link>
            <Link
              sx={{ color: "primary", fontWeight: 500, mr: "40px" }}
              link="/calendar"
            >
              /Calendar
            </Link>
            <Link sx={{ color: "primary", fontWeight: 500 }} link="/stories">
              /Stories
            </Link>
          </div>
        </div>
      </div>
    </Hero>
  )
}

export default NotFound
