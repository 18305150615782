/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import FeaturedStories from "../featured-stories"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../container"
import Ad from "../ad"

const FeaturedStoriesPattern = ({ blade, desktopAdUrl, mobileAdUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        featuredStoriesSequence {
          orderItems {
            contentful_id
            author {
              name
            }
            title
            images {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <Container>
        <div
          sx={{
            my: [0, 0, "50px"],
          }}
        >
          <div sx={{ mb: "25px" }}>
            <h5 sx={{ fontSize: "30px", mb: "15px" }}>/Stories</h5>
          </div>
          <FeaturedStories
            stories={data.contentfulSite.featuredStoriesSequence.orderItems.slice(
              1
            )}
          />
        </div>
      </Container>
      <Ad
        desktopAdUrl={desktopAdUrl}
        mobileAdUrl={mobileAdUrl}
        sx={{ mb: "80px" }}
      />
    </React.Fragment>
  )
}

export default FeaturedStoriesPattern
