/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import { useCarouselNavigation } from "./use-carousel-navigation"

const CarouselNavigation = ({
  nextControllerStyle,
  previousControllerStyle,
}) => {
  const carouselNavigationButtonStyle = {
    backgroundColor: theme => theme.colors.backgrounds.primary,
  }
  const { onNext, onPrevious, showNext, showPrevious } = useCarouselNavigation()

  return (
    <React.Fragment>
      <button
        sx={{
          ...carouselNavigationButtonStyle,
          left: "5%",
          opacity: showPrevious ? 1 : 0.4,
          ...(showPrevious && { cursor: "pointer" }),
          ...previousControllerStyle,
        }}
        disabled={!showPrevious}
        onClick={e => {
          e.preventDefault()
          onPrevious()
        }}
      >
        Prev
      </button>
      <button
        sx={{
          ...carouselNavigationButtonStyle,
          right: "5%",
          opacity: showNext ? 1 : 0.4,
          ...(showNext && { cursor: "pointer" }),
          ...nextControllerStyle,
        }}
        disabled={!showNext}
        onClick={e => {
          e.preventDefault()
          onNext()
        }}
      >
        Next
      </button>
    </React.Fragment>
  )
}

export default CarouselNavigation
