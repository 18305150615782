import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useContentfulClient } from "../hooks/use-contentful-client"
import { useAPI } from "../hooks/use-api"

export const useOrganizationService = () => {
  const apiUrl = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
  `).site.siteMetadata.apiUrl

  const { getClient } = useContentfulClient()
  const [response, setResponse] = React.useState({
    data: null,
    loading: false,
    error: null,
  })
  const api = useAPI(setResponse)

  const checkNameIsUnique = React.useCallback(
    async name => {
      const entries = await getClient().getEntries({
        content_type: "organization",
        "fields.name": name,
      })
      return !entries.total
    },
    [getClient]
  )

  const findBySlug = React.useCallback(
    async slug => {
      setResponse(data => ({ ...data, loading: true }))
      try {
        const entries = await getClient().getEntries({
          content_type: "organization",
          "fields.secretSlug": slug,
        })
        setResponse({ data: entries.items[0].fields, loading: false })
      } catch (err) {
        setResponse(data => ({ ...data, loading: false, error: err }))
      }
    },
    [setResponse, getClient]
  )

  const post = React.useCallback(
    ({ data, files, callback }) => {
      api.post({
        data,
        files,
        callback,
        url: `${apiUrl}/organization`,
      })
    },
    [api, apiUrl]
  )
  return {
    checkNameIsUnique,
    findBySlug,
    post,
    response,
  }
}
