/** @jsx jsx */
import { jsx } from "theme-ui"
import Card from "./card"

const RowLayoutCard = ({ content, ...restProps }) => {
  return (
    <div sx={{ display: "flex", flexDirection: "row" }}>
      {content.contentCards &&
        content.contentCards.map(subcard => <Card content={subcard} />)}
    </div>
  )
}
export default RowLayoutCard
