/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import TabsController from "./common/context"
import TabList from "./common/tab-list"
import TabPanel from "./common/tab-panel"

const Tabs = ({ data }) => {
  return (
    <TabsController>
      <div>
        <TabList titles={data.map(item => item.title)} />
      </div>
      <div sx={{ border: "1px solid #dedede", padding: "10px" }}>
        <TabPanel content={data.map(item => item.content)} />
      </div>
    </TabsController>
  )
}
Tabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    })
  ),
}
export default Tabs
