/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Form from "./form"
import { useContactService } from "../services/contact-service"
const ContactForm = ({ ...restProps }) => {
  const { post, response } = useContactService()
  const onSubmit = data => {
    post({
      data,
      callback: () => {},
    })
  }
  const sections = [
    {
      marginBottom: true,
      fields: [
        {
          name: "organization",
          label: "Organization Name",
          type: "input",
          fullWidth: true,
        },
      ],
    },
    {
      marginBottom: false,
      fields: [
        {
          name: "firstName",
          label: "First Name*",
          required: true,
          type: "input",
        },
        {
          name: "lastName",
          label: "Last Name",
          type: "input",
        },
      ],
    },
    {
      marginBottom: true,
      fields: [
        {
          name: "email",
          label: "Email Address*",
          required: true,
          type: "email",
          validations: [
            {
              name: "validEmail",
              fn: () => data =>
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  data
                ),
              error: "Invalid Email",
            },
          ],
          fullWidth: true,
        },
        {
          name: "message",
          maxLength: 450,
          label: "Message*",
          required: true,
          type: "textarea",
          fullWidth: true,
        },
      ],
    },
  ]
  const errorMessages = {
    429: "Please wait 60 seconds before sending another request.",
    422: "Submitted data has validation errors.",
    500: "Internal server error, please try again.",
  }
  return (
    <div {...restProps}>
      {!response.data ? (
        <React.Fragment>
          <div>
            <h3>Contact Us</h3>
            <p sx={{ mb: 0 }}>
              You can reach us by phone or via email through the form below.
            </p>
            <p>415-305-0427</p>
          </div>
          <div>
            <Form
              sx={{ pr: [0, 0, "100px"] }}
              sections={sections}
              submitting={false}
              onSubmit={onSubmit}
              type="secondary"
              submitText="Send Message"
            />
          </div>
          <div sx={{ color: "primary", textAlign: "center" }}>
            {response.error && errorMessages[response.error]}
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div sx={{ px: "30px", py: "80px" }}>
            <h3> Thank you for getting in touch! </h3>
            We appreciate you contacting us. One of our colleagues will get back
            in touch with you soon!
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactForm
