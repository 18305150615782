/** @jsx jsx */
import { jsx } from "theme-ui"
import Hero from "../hero"
import Container from "../container"
import ContentCard from "../content-card"
import SwipeableView from "../swipeable-view"

const SwipeableViewPattern = ({ blade }) => {
  return (
    <Container>
      <ContentCard card={{ ...blade, patternType: "Blade" }} />
      <SwipeableView>
        {blade.contentCards.map((card, index) => (
          <Hero key={index} desktopImage={card.desktopImage}>
            <ContentCard card={card} renderRootImage={false} />
          </Hero>
        ))}
      </SwipeableView>
    </Container>
  )
}

export default SwipeableViewPattern
