/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import RichText from "../rich-text"
import Container from "../container"

function EventFormTitle() {
  const data = useStaticQuery(graphql`
    query {
      event: contentfulBlade(title: {}, name: { eq: "EventFormTitle" }) {
        title
        body {
          json
        }
      }
    }
  `)
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: "auto",
        pb: "0px",
        width: ["95%", "95%", "780px"],
        textAlign: ["center", "center", "unset"],
      }}
    >
      <h3>{data.event.title}</h3>
      <RichText
        data={data.event.body}
        textStyle={{
          fontSize: "17px",
          mx: "auto",
        }}
      />
    </Container>
  )
}

export default EventFormTitle
