/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import ReactDOM from "react-dom"
import { useIsClient } from "../../../hooks/use-is-client"
import EventTypeFilter from "./event-type-filter"
import LocationFilter from "./location-filter"
import Container from "../../container"
import { useWindowScroll, useClickAway, useMedia } from "react-use"
import magnifyingGlass from "../../../images/filter-search.svg"
import Highlights from "../highlights"
import FilterContainer from "../filter-container"
import DateRangeFilter from "./date-range-filter"
import PriceRangeFilter from "./price-range-filter"
import MoreOptionsFilter from "./more-options-filter"
import VenuesFilter from "./venues-filter"
import { useFilters } from "./hooks/use-filters"
import FilterBarButton from "./filter-bar-button"
import { formatDateForFilter, mediaQueryList } from "../../../helpers"
import SearchBar from "./search-bar"

const Filters = ({
  search,
  filter,
  setFilter,
  currentResults,
  desktopAdUrl,
  mobileAdUrl,
  ...restProps
}) => {
  const [openFilter, setOpenFilter] = React.useState(null)

  const {
    selectedExperience,
    setSelectedExperience,
    selectedDiscipline,
    setSelectedDiscipline,
    selectedNeighborhood,
    setSelectedNeighborhood,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedPriceRange,
    setSelectedPriceRange,
    moreOptions,
    selectedMoreOptions,
    setSelectedMoreOptions,
    selectedVenue,
    setSelectedVenue,
    searchedText,
    setSearchedText,
  } = useFilters(setFilter)

  const isClient = useIsClient()
  const header = isClient && document.getElementsByTagName("header")[0]
  const [scrolled, setScrolled] = React.useState(false)
  const { y: scrollY } = useWindowScroll()
  const [showSearchBar, setShowSearchBar] = React.useState(false)
  const mobileBreakpoint = useMedia(mediaQueryList({ mediaPrefix: false })[1])

  React.useEffect(() => {
    if (scrollY > 50) {
      setScrolled(true)
    } else if (scrollY === 0) {
      setScrolled(false)
    }
  }, [scrollY])
  const panelStyle = {
    position: ["initial", "initial", "initial", "relative"],
    top: scrolled ? "-6px" : "-16px",
    height: [
      "100%",
      "100%",
      "100%",
      scrolled ? "460px" : "400px",
      scrolled ? "600px" : "550px",
      scrolled ? "650px" : "600px",
      scrolled ? "750px" : "700px",
    ],
  }
  return !header ? null : (
    <React.Fragment>
      {ReactDOM.createPortal(
        <React.Fragment>
          <div
            sx={{
              backgroundColor: "swatches.secondary.lightTan",
              position: "sticky",
              top: 0,
            }}
          >
            <Container
              sx={{
                py: scrolled ? "20px" : ["20px", "20px", "30px"],
                display: "flex",
                justifyItems: "center",
                width: "100%",
              }}
            >
              <div
                sx={{
                  width: "100%",
                  mx: 0,
                }}
              >
                <h5
                  sx={{
                    maxHeight: "100px",
                    overflow: "hidden",
                    display: "block",
                    transition: "0.5s",
                    mb: "15px",
                    fontSize: "21px",
                    ...(scrolled && {
                      maxHeight: "0px",
                      mb: 0,
                      opacity: 0,
                    }),
                    display: ["none", "none", "none", "flex"],
                  }}
                >
                  Explore Upcoming Events in Your Area
                </h5>
                <div>
                  <div
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: [
                        "space-between",
                        "space-around",
                        "unset",
                      ],
                    }}
                  >
                    <FilterBarButton
                      onClick={() =>
                        setOpenFilter(filter =>
                          filter === "date-range" ? null : "date-range"
                        )
                      }
                      onClear={() => {
                        setSelectedStartDate(null)
                        setSelectedEndDate(null)
                      }}
                      selected={openFilter === "date-range"}
                      active={selectedStartDate || selectedEndDate}
                      sx={{ width: ["114px", "114px", "200px"] }}
                    >
                      {selectedStartDate && selectedEndDate ? (
                        <React.Fragment>
                          {formatDateForFilter(selectedStartDate)} -{" "}
                          {formatDateForFilter(selectedEndDate)}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>Date Range</React.Fragment>
                      )}
                    </FilterBarButton>
                    <FilterBarButton
                      onClick={() =>
                        setOpenFilter(filter =>
                          filter === "event-type" ? null : "event-type"
                        )
                      }
                      onClear={() => {
                        setSelectedDiscipline([])
                        setSelectedExperience([])
                      }}
                      selected={openFilter === "event-type"}
                      active={
                        selectedDiscipline.length > 0 ||
                        selectedExperience.length > 0
                      }
                      sx={{
                        width: ["114px", "114px", "200px"],
                      }}
                    >
                      {selectedDiscipline.length + selectedExperience.length ===
                      1 ? (
                        <React.Fragment>
                          {selectedDiscipline[0] || selectedExperience[0]}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Event Types
                          {selectedDiscipline.length > 0 ||
                          selectedExperience.length > 0
                            ? ` (${selectedExperience.length +
                                selectedDiscipline.length})`
                            : ""}
                        </React.Fragment>
                      )}
                    </FilterBarButton>
                    <FilterBarButton
                      onClick={() =>
                        setOpenFilter(filter =>
                          filter === "location" ? null : "location"
                        )
                      }
                      onClear={() => {
                        setSelectedNeighborhood([])
                      }}
                      selected={openFilter === "location"}
                      active={selectedNeighborhood.length > 0}
                      sx={{
                        width: ["114px", "114px", "200px"],
                        display: ["none", "none", "flex"],
                      }}
                    >
                      {selectedNeighborhood.length === 1 ? (
                        <React.Fragment>
                          {selectedNeighborhood[0]}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Location
                          {selectedNeighborhood.length > 0
                            ? ` (${selectedNeighborhood.length})`
                            : ""}
                        </React.Fragment>
                      )}
                    </FilterBarButton>
                    {/* Mobile */}
                    <FilterBarButton
                      onClick={() =>
                        setOpenFilter(filter =>
                          filter === "more-filters" ? null : "more-filters"
                        )
                      }
                      onClear={() => {
                        setSelectedNeighborhood([])
                        setSelectedPriceRange(values => ({
                          min: 0,
                          max: 500,
                          enabled: false,
                        }))
                        setSelectedMoreOptions([])
                        setSelectedVenue(null)
                      }}
                      selected={openFilter === "more-filters"}
                      active={
                        selectedNeighborhood.length > 0 ||
                        selectedPriceRange.enabled ||
                        selectedMoreOptions.length > 0 ||
                        selectedVenue
                      }
                      sx={{
                        width: ["114px", "114px", "200px"],
                        display: ["flex", "flex", "none"],
                      }}
                    >
                      More Filters
                      {selectedNeighborhood.length > 0 ||
                      selectedPriceRange.enabled ||
                      selectedVenue ||
                      selectedMoreOptions.length > 0
                        ? ` (${selectedNeighborhood.length +
                            selectedMoreOptions.length +
                            (selectedVenue ? 1 : 0) +
                            (selectedPriceRange.enabled ? 1 : 0)})`
                        : ""}
                    </FilterBarButton>
                    {/* Desktop */}
                    <FilterBarButton
                      onClick={() =>
                        setOpenFilter(filter =>
                          filter === "more-filters" ? null : "more-filters"
                        )
                      }
                      onClear={() => {
                        setSelectedPriceRange(values => ({
                          min: 0,
                          max: 500,
                          enabled: false,
                        }))
                        setSelectedMoreOptions([])
                        setSelectedVenue(null)
                      }}
                      selected={openFilter === "more-filters"}
                      active={
                        selectedPriceRange.enabled ||
                        selectedMoreOptions.length > 0 ||
                        selectedVenue
                      }
                      sx={{
                        width: ["114px", "114px", "200px"],
                        display: ["none", "none", "flex"],
                      }}
                    >
                      More Filters
                      {selectedPriceRange.enabled ||
                      selectedMoreOptions.length > 0 ||
                      selectedVenue
                        ? ` (${selectedMoreOptions.length +
                            (selectedVenue ? 1 : 0) +
                            (selectedPriceRange.enabled ? 1 : 0)})`
                        : ""}
                    </FilterBarButton>
                    <FilterBarButton
                      onClick={() => {
                        setShowSearchBar(value =>
                          searchedText ? false : !value
                        )
                        setSearchedText(null)
                      }}
                      selected={showSearchBar || searchedText}
                      sx={{ display: ["none", "none", "flex"] }}
                    >
                      {showSearchBar || searchedText ? (
                        <span sx={{ fontWeight: 600 }}>&#10005;</span>
                      ) : (
                        <img
                          src={magnifyingGlass}
                          sx={{
                            width: "20px",
                          }}
                        />
                      )}
                    </FilterBarButton>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          {openFilter && (
            <div
              sx={{
                background: "transparent",
                width: "100%",
                height: "100%",
                position: "fixed",
                top: 0,
                lef: 0,
              }}
              onClick={() => setOpenFilter(null)}
            ></div>
          )}
          <div
            sx={{
              position: "relative",
              zIndex: 1,
              overflowY: ["auto", "auto", "auto", "unset"],
              maxWidth: theme =>
                theme.spacing.horizontal.map(spacing => 1200 + spacing * 2),
              minWidth: 0,
              mx: "auto",
              px: theme => [0, 0, 0, theme.spacing.horizontal[2]],
              width: "100%",
              flexGrow: 1,
              height: ["unset", "unset", "unset", 0],
            }}
          >
            {openFilter === "date-range" && (
              <div
                sx={{
                  ...panelStyle,
                  width: ["100%", "100%", "100%", scrolled ? "650px" : "675px"],
                  left: 0,
                  height: "unset",
                }}
              >
                <FilterContainer
                  onApply={() => {
                    if (selectedStartDate && !selectedEndDate) {
                      setSelectedEndDate(selectedStartDate)
                    }
                    setOpenFilter(null)
                  }}
                  onClear={() => {
                    setSelectedStartDate(null)
                    setSelectedEndDate(null)
                  }}
                >
                  <DateRangeFilter
                    {...{
                      selectedEndDate,
                      setSelectedEndDate,
                      selectedStartDate,
                      setSelectedStartDate,
                      currentResults,
                    }}
                  />
                </FilterContainer>
              </div>
            )}
            {openFilter === "event-type" && (
              <div
                sx={{
                  ...panelStyle,
                  width: ["100%", "100%", "100%", "602px"],
                  left: [0, 0, 0, "215px"],
                }}
              >
                <FilterContainer
                  onApply={() => setOpenFilter(null)}
                  onClear={() => {
                    setSelectedDiscipline([])
                    setSelectedExperience([])
                  }}
                >
                  <EventTypeFilter
                    {...{
                      selectedDiscipline,
                      setSelectedDiscipline,
                      selectedExperience,
                      setSelectedExperience,
                      currentResults,
                    }}
                  />
                </FilterContainer>
              </div>
            )}
            {openFilter === "location" && (
              <div
                sx={{
                  ...panelStyle,
                  width: ["100%", "100%", "100%", "353px"],
                  left: [0, 0, 0, "428px"],
                }}
              >
                <FilterContainer
                  onApply={() => setOpenFilter(null)}
                  onClear={() => {
                    setSelectedNeighborhood([])
                  }}
                  sx={{ pb: "30px" }}
                >
                  <LocationFilter
                    {...{
                      selectedNeighborhood,
                      setSelectedNeighborhood,
                      currentResults,
                    }}
                  />
                </FilterContainer>
              </div>
            )}
            {openFilter === "more-filters" && (
              <div
                sx={{
                  ...panelStyle,
                  width: ["100%", "100%", "100%", "600px"],
                  left: [0, 0, 0, "242px", "242px", "642px", "642px"],
                }}
              >
                {!mobileBreakpoint ? (
                  <FilterContainer
                    sx={{ height: "100%" }}
                    onApply={() => {
                      setOpenFilter(null)
                    }}
                    onClear={() => {
                      setSelectedNeighborhood([])
                      setSelectedPriceRange(values => ({
                        min: 0,
                        max: 500,
                        enabled: false,
                      }))
                      setSelectedMoreOptions([])
                      setSelectedVenue(null)
                    }}
                  >
                    <h5
                      sx={{
                        fontSize: "18px",
                        borderBottom: "1px solid #c0c0c0",
                        pb: "5px",
                        mb: "20px",
                      }}
                    >
                      Neighborhood
                    </h5>
                    <LocationFilter
                      sx={{ mb: "30px" }}
                      {...{
                        selectedNeighborhood,
                        setSelectedNeighborhood,
                        currentResults,
                      }}
                    />
                    <PriceRangeFilter
                      sx={{
                        ...(selectedMoreOptions &&
                          (selectedMoreOptions.includes("Free Events") ||
                            selectedMoreOptions.includes("Donation Based")) && {
                            pointerEvents: "none",
                            opacity: 0.6,
                          }),
                      }}
                      {...{
                        selectedPriceRange,
                        setSelectedPriceRange,
                      }}
                    />
                    <MoreOptionsFilter
                      sx={{ mt: "30px" }}
                      {...{
                        selectedMoreOptions,
                        setSelectedMoreOptions,
                        moreOptions,
                        currentResults,
                      }}
                    />
                    <VenuesFilter
                      {...{
                        selectedVenue,
                        setSelectedVenue,
                        currentResults,
                      }}
                    />
                  </FilterContainer>
                ) : (
                  <FilterContainer
                    sx={{
                      maxHeight: "100%",
                    }}
                    onApply={() => {
                      setOpenFilter(null)
                    }}
                    onClear={() => {
                      setSelectedPriceRange(values => ({
                        min: 0,
                        max: 500,
                        enabled: false,
                      }))
                      setSelectedMoreOptions([])
                      setSelectedVenue(null)
                    }}
                  >
                    <div
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        columnGap: "70px",
                      }}
                    >
                      <PriceRangeFilter
                        sx={{
                          ...(selectedMoreOptions &&
                            (selectedMoreOptions.includes("Free Events") ||
                              selectedMoreOptions.includes(
                                "Donation Based"
                              )) && {
                              pointerEvents: "none",
                              opacity: 0.6,
                            }),
                        }}
                        {...{
                          selectedPriceRange,
                          setSelectedPriceRange,
                        }}
                      />
                      <MoreOptionsFilter
                        {...{
                          selectedMoreOptions,
                          setSelectedMoreOptions,
                          moreOptions,
                          currentResults,
                        }}
                      />
                    </div>
                    <VenuesFilter
                      {...{
                        selectedVenue,
                        setSelectedVenue,
                        currentResults,
                      }}
                    />
                  </FilterContainer>
                )}
              </div>
            )}
          </div>
          <div
            sx={{
              height: [
                "auto",
                "auto",
                showSearchBar || searchedText ? "120px" : 0,
              ],
              opacity: [1, 1, showSearchBar || searchedText ? 1 : 0],
              transition: "0.5s",
              overflow: "hidden",
            }}
          >
            <SearchBar
              sx={{
                display: !openFilter ? "block" : ["none", "block", "block"],
              }}
              scrolled={scrolled}
              searchedText={searchedText}
              setSearchedText={setSearchedText}
            />
          </div>
        </React.Fragment>,
        header
      )}
      {selectedNeighborhood.length === 0 &&
        selectedExperience.length === 0 &&
        !selectedEndDate &&
        !selectedStartDate &&
        selectedDiscipline.length === 1 &&
        !selectedPriceRange.enabled &&
        selectedMoreOptions.length === 0 &&
        !searchedText && (
          <Highlights
            selectedDiscipline={selectedDiscipline[0]}
            {...{ desktopAdUrl, mobileAdUrl }}
          />
        )}
    </React.Fragment>
  )
}

export default Filters
