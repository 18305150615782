/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "../container"
import RichText from "../rich-text"
import { getSubsets } from "../../helpers"
import PlaceholderStaff from "../../images/Placeholder-staff.jpg"

const Portrait = ({ children, card, isSelected, ...restProps }) => {
  const [name, ...rest] = card.title.split("/")
  const position = rest.join("/")
  return (
    <div
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        width: [null, null, null, null, "240px"],
      }}
      {...restProps}
    >
      <div sx={{ position: "relative" }}>
        <div
          sx={{
            position: "relative",
            borderBottom: "5px solid #CC5C45",
            display: "flex",
          }}
        >
          <div
            sx={{
              position: "absolute",
              opacity: isSelected ? 1 : 0,
              transition: "0.2s",
              width: "100%",
              height: "100%",
              clipPath: "polygon(68% 0, 100% 0, 100% 100%, 30% 100%)",
              background: "rgba(230, 89, 64, 0.6)",
            }}
          />
          {card.desktopImage ? (
            <img
              alt=""
              src={card.desktopImage.file.url}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            <img
              alt=""
              src={PlaceholderStaff}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      </div>
      <h4
        sx={{
          fontSize: "17px",
          color: "dark",
          mt: "14px",
          mb: 0,
        }}
      >
        {name}
      </h4>
      <h6
        sx={{
          fontSize: "17px",
          color: "dark",
          mt: "8px",
          mb: 0,
        }}
      >
        {position}
      </h6>
      <div
        sx={{
          display: isSelected ? "block" : "none",
          width: "100%",
          position: "relative",
          height: 0,
          opacity: isSelected ? 1 : 0,
          transition: "0.2s",
        }}
      >
        {children}
      </div>
    </div>
  )
}
const StaffAboutPattern = ({ blade }) => {
  const subsets = React.useMemo(
    () =>
      blade.contentCards
        ? [
            getSubsets(blade.contentCards, [2]),
            getSubsets(blade.contentCards, [4]),
            getSubsets(blade.contentCards, [4, 3, 3, 3]),
          ]
        : [[], []],
    [blade]
  )
  const [selected, setSelected] = React.useState(null)
  const styles = [
    {
      height: "394px",
      elements: [
        { left: "0px", top: "45px", body: { left: "0px" } },
        { left: "307px", top: "0px", body: { left: "0px" } },
        { left: "614px", top: "140px", body: { left: "0px" } },
        { left: "920px", top: "0px", body: { right: "0px", width: "390px" } },
      ],
    },
    {
      height: "344px",
      elements: [
        { left: "207px", top: "0px", body: { left: "0px" } },
        { left: "530px", top: "150px", body: { left: "0px" } },
        { left: "920px", top: "90px", body: { right: "0px", width: "390px" } },
      ],
    },
    {
      height: "196px",
      elements: [
        { left: "0px", top: "0px", body: { left: "0px" } },
        { left: "309px", top: "150px", body: { left: "0px" } },
        {
          left: "819px",
          top: "90px",
          body: { right: "0", width: "510px" },
        },
      ],
    },
    {
      height: "570px",
      elements: [
        { left: "0px", top: "220px", body: { left: "0px" } },
        { left: "500px", top: "270px", body: { left: "0px" } },
        { left: "920px", top: "240px", body: { right: "0", width: "420px" } },
      ],
    },
  ]
  const isSelected = (responsiveSubsetIndex, subsetIndex, rowIndex, index) =>
    selected &&
    selected.responsiveSubsetIndex === responsiveSubsetIndex &&
    selected.subsetIndex === subsetIndex &&
    selected.rowIndex === rowIndex &&
    selected.index === index
  const renderBody = card => (
    <div>
      <RichText data={card.body} />
    </div>
  )
  const renderMobile = responsiveSubsetIndex => (
    <React.Fragment>
      {subsets[responsiveSubsetIndex].map((subset, subsetIndex) => (
        <div key={subsetIndex}>
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            {subset.map((row, rowIndex) =>
              row.map((card, index) => (
                <Portrait
                  sx={{
                    p: ["2px", "8px"],
                    mb: ["10px", "10px", 0],
                    maxWidth: ["50%", "50%", "25%", "25%"],
                    flexBasis: ["50%", "50%", "25%", "25%"],
                  }}
                  key={rowIndex + "-" + index}
                  card={card}
                  isSelected={isSelected(
                    responsiveSubsetIndex,
                    subsetIndex,
                    rowIndex,
                    index
                  )}
                  onMouseEnter={() =>
                    setSelected({
                      responsiveSubsetIndex,
                      subsetIndex,
                      rowIndex,
                      index,
                    })
                  }
                  onMouseLeave={() => setSelected(null)}
                >
                  <p>—</p>
                </Portrait>
              ))
            )}
          </div>
          {selected &&
            selected.subsetIndex === subsetIndex &&
            isSelected(
              responsiveSubsetIndex,
              selected.subsetIndex,
              selected.rowIndex,
              selected.index
            ) && (
              <div sx={{ py: "20px", m: ["2px", "8px"] }}>
                {renderBody(
                  subsets[responsiveSubsetIndex][selected.subsetIndex][
                    selected.rowIndex
                  ][selected.index]
                )}
              </div>
            )}
        </div>
      ))}
    </React.Fragment>
  )
  return (
    <Container
      outerStyle={{
        backgroundColor: "backgrounds.primary",
        "&~div>div": {
          opacity: selected ? "0.2" : 1,
        },
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        mt: "30px",
      }}
    >
      <h3 sx={{ mx: "auto", fontSize: "24px" }}>{blade.title}</h3>
      <div sx={{ mt: "20px" }}>
        <div
          sx={{
            width: "100%",
            display: ["block", "block", "none"],
          }}
        >
          {renderMobile(0)}
        </div>
        <div
          sx={{
            width: "100%",
            display: ["none", "none", "block", "block", "none"],
          }}
        >
          {renderMobile(1)}
        </div>
        <div sx={{ display: ["none", "none", "none", "none", "block"] }}>
          {subsets[2].map((subset, subsetIndex) => (
            <React.Fragment key={subsetIndex}>
              {subset.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  sx={{
                    position: "relative",
                    pointerEvents: "none",
                    height: styles[rowIndex].height,
                  }}
                >
                  {row.map((card, index) => (
                    <Portrait
                      key={index}
                      card={card}
                      isSelected={isSelected(2, subsetIndex, rowIndex, index)}
                      onMouseEnter={() =>
                        setSelected({
                          responsiveSubsetIndex: 2,
                          subsetIndex,
                          rowIndex,
                          index,
                        })
                      }
                      onMouseLeave={() => setSelected(null)}
                      sx={{
                        pointerEvents: "all",
                        m: 0,
                        opacity: 1,
                        ...(selected &&
                          (isSelected(2, subsetIndex, rowIndex, index)
                            ? { zIndex: 9 }
                            : { opacity: 0.05 })),
                        transition: "opacity 0.2s",
                        position: "absolute",
                        left: styles[rowIndex].elements[index].left,
                        top: styles[rowIndex].elements[index].top,
                      }}
                    >
                      <p>—</p>
                      <div
                        sx={{
                          position: "absolute",
                          top: "15px",
                          width: "475px",
                          ...styles[rowIndex].elements[index].body,
                        }}
                      >
                        {isSelected(2, subsetIndex, rowIndex, index) &&
                          renderBody(card)}
                      </div>
                    </Portrait>
                  ))}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default StaffAboutPattern
