/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Label from "../label"
import FieldInput from "./field-input"
import FieldAutosuggest from "./field-autosuggest"
import FieldTextarea from "./field-textarea"
import FieldSelect from "./field-select"
import FieldCheckbox from "./field-checkbox"
import FieldRadioGroup from "./field-radioGroup"
import FieldCheckboxGroup from "./field-checkbox-group"
import FieldImage from "./field-image"
import FieldDate from "./field-date"
import FieldEmail from "./field-email"
const Field = ({ field, errors, watchData, register, setValue }) => {
  const isDisabled = field.disabledBy && field.disabledBy(watchData)
  const isHidden = field.hiddenBy && field.hiddenBy(watchData)

  const fieldError = React.useMemo(() => {
    if (field.name && field.name.includes(".")) {
      const fieldNameSplitted = field.name.split(".")
      return (
        errors[fieldNameSplitted[0]] &&
        errors[fieldNameSplitted[0]][fieldNameSplitted[1]]
      )
    } else return errors[field.name]
  })
  const hasErrors = !!fieldError

  const renderInput = () => {
    let validate = field.validations && {}
    if (validate)
      field.validations.forEach(
        validation => (validate[validation.name] = validation.fn(watchData))
      )
    switch (field.type) {
      case "input":
        return (
          <FieldInput
            {...{
              isDisabled,
              field,
              register,
              validate,
              fieldError,
              watchData,
              setValue,
            }}
          />
        )
      case "autosuggest":
        return (
          <FieldAutosuggest
            {...{ register, field, validate, setValue, watchData }}
          />
        )
      case "textarea":
        return <FieldTextarea {...{ field, register, validate, fieldError }} />
      case "select":
        return <FieldSelect {...{ field, isDisabled, register, validate }} />
      case "checkbox":
        return <FieldCheckbox {...{ field, register, validate }} />
      case "radio-group":
        return (
          <FieldRadioGroup
            {...{ register, field, setValue, watchData, validate }}
          />
        )
      case "checkbox-group":
        return (
          <FieldCheckboxGroup {...{ register, field, watchData, setValue }} />
        )
      case "image":
        return <FieldImage {...{ register, field, validate, setValue }} />
      case "email":
        return <FieldEmail {...{ field, register, validate, fieldError }} />
      case "date": {
        return (
          <FieldDate {...{ register, field, validate, setValue, watchData }} />
        )
      }
      case "content": {
        return field.content
      }
      default:
        return null
    }
  }
  const renderGroup = () => {
    return (
      <div sx={{ p: "20px", border: "1px solid #c0c0c0" }}>
        {field.fields.map((field, index) => (
          <Field
            key={index}
            {...{
              field,
              errors,
              watchData,
              register,
              setValue,
            }}
          />
        ))}
      </div>
    )
  }
  const renderError = () => {
    if (!hasErrors) return null
    const requiredError = fieldError.type === "required"
    return (
      <div sx={{ fontSize: "12px", color: "primary", mt: "5px" }}>
        {requiredError && <div>This field is required</div>}
        {field.validations &&
          field.validations.map(
            validation =>
              fieldError.type === validation.name && (
                <div key={validation.name}>{validation.error}</div>
              )
          )}
      </div>
    )
  }
  return (
    !isHidden && (
      <div sx={{ mb: "20px", ...field.sx }}>
        {field.label && (
          <Label
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: isDisabled ? "#c0c0c0" : hasErrors ? "primary" : "#888",
            }}
            name={field.name}
          >
            {field.label}
            {field.maxLength && (
              <span
                sx={{
                  color:
                    watchData[field.name] &&
                    watchData[field.name].length > field.maxLength
                      ? "primary"
                      : "#888",
                }}
              >
                {watchData[field.name] ? watchData[field.name].length : 0}/
                {field.maxLength}
              </span>
            )}
          </Label>
        )}
        {field.additionalLabel && (
          <Label
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: isDisabled ? "#c0c0c0" : "#888",
            }}
            name={field.name}
          >
            {field.additionalLabel}
          </Label>
        )}
        {field.type === "group" ? renderGroup() : renderInput()}
        {renderError()}
      </div>
    )
  )
}
export default Field
