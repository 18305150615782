/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { styles } from "../gatsby-plugin-theme-ui/index"

const Textarea = React.forwardRef((props, ref) => (
  <textarea
    sx={{
      ...styles.input,
      borderColor: "#c0c0c0",
      ":focus": { borderColor: "#c0c0c0" },
    }}
    ref={ref}
    {...props}
  ></textarea>
))
export default Textarea
