/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import MoreStoryCard from "./story-card-more"

const MoreStories = ({ stories }) => {
  return (
    <React.Fragment>
      <div
        sx={{
          display: ["grid"],
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"],
          columnGap: "2px",
          rowGap: "3px",
        }}
      >
        {stories.map((story, index) => (
          <React.Fragment key={index}>
            <MoreStoryCard
              type="secondary"
              sx={{
                display: "flex",
                mb: "18px",
                h4: {
                  fontSize: ["24px", "24px", "28px"],
                },
              }}
              story={story}
            />
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  )
}

export default MoreStories
