/** @jsx jsx */
import { jsx } from "theme-ui"
import FileDropzone from "../file-dropzone"
const FieldImage = ({ register, field, validate, setValue }) => {
  register(
    { name: field.name },
    { required: !!field.required, ...(validate && { validate }) }
  )

  return (
    <FileDropzone
      multiple={false}
      onDrop={files =>
        setValue(field.name, files, {
          shouldDirty: true,
        })
      }
    >
      {field.children}
    </FileDropzone>
  )
}
export default FieldImage
