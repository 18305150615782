/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Card from "./card"

const ContentCard = ({ card, renderRootImage, type, ...restProps }) => {
  return <Card content={card} hideImage={!renderRootImage} {...restProps} />
}

ContentCard.propTypes = {
  content: PropTypes.object,
  renderRootImage: PropTypes.bool,
}

export default ContentCard
