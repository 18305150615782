/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../container"
import Link from "../../components/link"

const AcknowledgmentsSectionPattern = ({ blade }) => {
  return (
    <Container
      outerStyle={{
        backgroundColor: "backgrounds.primary",
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "30px",
      }}
    >
      <div sx={{ display: "flex", flexDirection: "row" }}>
        {blade.contentCards &&
          blade.contentCards.map((card, index) => (
            <div
              key={index}
              sx={{
                px: "45px",
                borderRight: "1px solid #D5D3D3",
                ":last-of-type": { borderRight: "0px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Link link={card?.url} target="_blank">
                <h6 sx={{ mx: "auto", fontSize: "15px", color: "#888888" }}>
                  {card?.title}
                </h6>
                <img alt={card?.title} src={card?.desktopImage.file.url} />
              </Link>
            </div>
          ))}
      </div>
    </Container>
  )
}

export default AcknowledgmentsSectionPattern
