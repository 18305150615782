/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Form from "../../form"
import { useStaticQuery, graphql } from "gatsby"
import { useEventService } from "../../../services/event-service"
import { useCollectionService } from "../../../services/collection-service"
const EventFormAdditional = ({ onSubmit, organization, ...restProps }) => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulDiscipline {
        nodes {
          disciplineName
        }
      }
      allContentfulExperience {
        nodes {
          experienceName
        }
      }
    }
  `)
  const disciplines = query.allContentfulDiscipline.nodes.map(
    node => node.disciplineName
  )
  const {
    findCollectionsByPartialName,
    checkNameIsUnique,
    response: { data: collections },
  } = useCollectionService()
  const {
    getFields,
    response: { data: fields },
  } = useEventService()
  const pricingOptions = React.useMemo(
    () =>
      fields &&
      fields
        .find(field => field.id === "pricingType")
        .validations.find(validation => !!validation.in).in,
    [fields]
  )
  const discountOptions = React.useMemo(
    () =>
      fields &&
      fields
        .find(field => field.id === "discountAvailable")
        .items.validations.find(validation => !!validation.in).in,
    [fields]
  )
  React.useEffect(() => {
    if (!fields) getFields()
  }, [fields, getFields])
  React.useEffect(() => {
    findCollectionsByPartialName(null, organization)
  }, [organization, findCollectionsByPartialName])
  const sections = [
    {
      fields: [
        {
          name: "ticketUrl",
          label: "Ticket URL",
          type: "input",
          fullWidth: true,
        },
        {
          name: "eventOrVenueUrl",
          label: "Event or Venue URL",
          type: "input",
          fullWidth: true,
        },
      ],
    },
    {
      type: "group",
      fields: [
        {
          type: "group",
          sx: {
            "&>div": { border: 0 },
          },
          fields: [
            {
              name: "pricingType",
              required: true,
              label: "Ticket Prices*",
              type: "radio-group",
              sx: {
                "&>div": { border: 0 },
              },
              options: pricingOptions,
            },
            {
              type: "group",
              sx: {
                "&>div": {
                  display: "flex",
                  flexDirection: "row",
                  border: 0,
                  "&>div": {
                    mx: "5px",
                    width: "50%",
                    border: 0,
                    mb: ["-55px", "-55px", "-40px"],
                    mt: "-10px",
                  },
                },
              },
              fields: [
                {
                  disabledBy: watchData =>
                    watchData["pricingType"] !== "Custom",
                  name: "minPrice",
                  label: "Min Price ($)",
                  type: "input",
                  attributes: {
                    type: "number",
                    min: 0,
                  },
                  validations: [
                    {
                      name: "required",
                      fn: watchData => data =>
                        watchData["pricingType"] !== "Custom" || !!data,
                    },
                  ],
                  onDataChange: (setValue, watchData) => {
                    if (parseInt(watchData["minPrice"]) < 0) {
                      setValue(0)
                    }
                  },
                },
                {
                  disabledBy: watchData =>
                    watchData["pricingType"] !== "Custom",
                  name: "maxPrice",
                  label: "Max Price ($)",
                  type: "input",
                  attributes: {
                    type: "number",
                    min: 0,
                  },
                  validations: [
                    {
                      name: "required",
                      fn: watchData => data =>
                        watchData["pricingType"] !== "Custom" || !!data,
                    },
                  ],
                  onDataChange: (setValue, watchData) => {
                    if (
                      watchData["minPrice"] &&
                      parseInt(watchData["maxPrice"] || "0") <
                        parseInt(watchData["minPrice"])
                    ) {
                      setValue(watchData["minPrice"])
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          type: "group",
          sx: {
            "&>div": { border: 0 },
          },
          fields: [
            {
              name: "discountAvailable",
              required: false,
              label: (
                <span>
                  Discounts Available
                  <span sx={{ fontStyle: "italic" }}>
                    (Select all that apply)
                  </span>
                </span>
              ),
              type: "checkbox-group",
              options: discountOptions,
            },
          ],
        },
      ],
    },

    {
      fields: [
        {
          name: "eventPhoneNumber",
          label: "Event Phone Number",
          type: "input",
        },
        {
          type: "group",
          fullWidth: true,
          sx: {
            "&>div": {
              background: "#f7f7f5",
              display: "grid",
              gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
              "&>*": {
                display: "inline-block",
              },
            },
          },
          fields: [
            {
              type: "content",
              content: (
                <div
                  sx={{ fontSize: "12px", lineHeight: "140%", height: "100%" }}
                >
                  <h6 sx={{ fontSize: "18px", fontWeight: 500, mb: "10px" }}>
                    Do you want to combine this event with other events into a
                    Collection?
                  </h6>
                  <p>
                    Collections are a group of events that span multiple venues
                    on multiple days (e.g. SF Film Festival){" "}
                  </p>
                </div>
              ),
            },
            {
              name: "collection",
              label: "Collection Name",
              fullWidth: true,
              required: true,
              type: "autosuggest",
              placeholder: "Enter Name",
              addText: "+ Add New Collection",
              fetch: ({ value }) => {
                findCollectionsByPartialName(value, organization)
              },
              suggestions: collections || [],
              shouldRenderSuggestions: () => true,
              clear: () => findCollectionsByPartialName(null),
              getValue: collection => collection.name,
              render: collection => (
                <React.Fragment>{collection.name}</React.Fragment>
              ),
              validations: [
                {
                  name: "uniqueCollection",
                  fn: watchData => async data => {
                    if (watchData["collection-isNew"]) {
                      return await checkNameIsUnique(data)
                    }
                    return true
                  },
                  error: "Collection name already exists",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      fields: [
        {
          type: "group",
          hiddenBy: watchData => !watchData["collection-isNew"],
          fullWidth: true,
          sx: {
            "&>div": {
              p: 0,
              border: 0,
              columnCount: [1, 1, 2],
              "&>*": { breakInside: "avoid", pageBreakInside: "avoid" },
            },
            mb: 0,
          },
          fields: [
            {
              name: "newCollection.description",
              label: "Collection Description*",
              maxLength: 250,
              validations: [
                {
                  name: "required",
                  fn: watchData => data =>
                    watchData["collection-isNew"] ? !!data : true,
                },
              ],
              type: "textarea",
              height: "103px",
            },
            {
              name: "newCollection.discipline",
              label: "Discipline*",
              validations: [
                {
                  name: "required",
                  fn: watchData => data =>
                    watchData["collection-isNew"] ? !!data : true,
                },
              ],
              type: "select",
              sx: {
                select: {
                  appearance: "none",
                  backgroundColor: "white",
                  "&::-ms-expand": {
                    display: "none",
                  },
                },
              },
              options: disciplines,
            },
            {
              name: "collectionImage",
              label: "Upload Collection Image*",
              type: "image",
              validations: [
                {
                  name: "required",
                  fn: watchData => data =>
                    watchData["collection-isNew"] ? !!data : true,
                },
              ],
              children: (
                <React.Fragment>
                  <p sx={{ mb: "15px" }}>
                    Drag and drop or choose a file on your local drive.
                  </p>
                  <p sx={{ mb: "41px" }}>
                    For best results, image size should be 1200px x 800px, JPG
                    or PNG saved for the web.
                  </p>
                </React.Fragment>
              ),
            },
          ],
        },
      ],
    },
    {
      fields: [
        {
          hiddenBy: watchData => !watchData["collection-isNew"],
          name: "newCollection.website",
          label: "Collection URL",
          type: "input",
        },
      ],
    },
    {
      fields: [
        {
          hiddenBy: watchData => !watchData["collection-isNew"],
          validations: [
            {
              name: "required",
              fn: watchData => data =>
                watchData["collection-isNew"] ? !!data : true,
            },
          ],
          name: "newCollection.startDate",
          label: "Start Date*",
          type: "date",
        },
        {
          hiddenBy: watchData => !watchData["collection-isNew"],
          name: "newCollection.endDate",
          label: "End Date",
          type: "date",
          getMinDate: watchData =>
            watchData["newCollection.startDate"] &&
            new Date(watchData["newCollection.startDate"]),
          onDataChange: (setValue, watchData) => {
            if (
              watchData["newCollection.startDate"] &&
              (!watchData["newCollection.endDate"] ||
                new Date(watchData["newCollection.startDate"]) >
                  new Date(watchData["newCollection.endDate"]))
            ) {
              setValue(watchData["newCollection.startDate"])
            }
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "submittedBy",
          label: "Submitter's Email*",
          required: true,
          validations: [
            {
              name: "validEmail",
              fn: () => data =>
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  data
                ),
              error: "Invalid Email",
            },
          ],
          type: "email",
        },
      ],
    },
  ]
  return (
    <div {...restProps}>
      <React.Fragment>
        <Form
          sections={sections}
          submitting={false}
          onSubmit={onSubmit}
          type="secondary"
          submitText="Continue"
        />
      </React.Fragment>
    </div>
  )
}

export default EventFormAdditional
