/** @jsx jsx */
import { jsx } from "theme-ui"
import EventCard from "../event-card"
import CollectionCard from "../collection-card"

const RecentlyViewedEvents = ({ events, ...restProps }) => {
  return (
    <div sx={{ mb: "40px" }} {...restProps}>
      <h3
        sx={{
          fontSize: "30px",
          mt: "40px",
        }}
      >
        Recently Viewed Events
      </h3>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "1fr",
            "1fr 1fr",
            "1fr 1fr 1fr",
            "1fr 1fr 1fr 1fr",
          ],
          columnGap: "2px",
          rowGap: "80px",
        }}
      >
        {events.map(entry =>
          entry.type === "event" ? (
            <EventCard event={entry} />
          ) : (
            <CollectionCard
              collection={{
                ...entry,
                image: entry.image.file.url,
                disciplineName: entry.discipline?.disciplineName,
                description: entry.description?.description,
              }}
            />
          )
        )}
      </div>
    </div>
  )
}

export default RecentlyViewedEvents
